import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'

export default function UsersCount(props) {

    const usersList = useSelector(state => state.usersList);
    const { users,
        loading,
        error } = usersList;

    useEffect(() => {

    }, [users]);

    return (
        <React.Fragment>
            {loading ? <div></div> :
                error ? <div>{error}</div> :

                    <p className="m-0 text-muted">{users.length} Users</p>
            }
        </React.Fragment>
    );
}