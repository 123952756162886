import React, { useEffect, useMemo, useState } from 'react';
import DataTable from 'react-data-table-component';
import { useSelector, useDispatch } from 'react-redux';
import { listHosts, deleteHost, selectedHost, toggleHostsSidebar } from '../../actions/hostsActions';
import Swal from 'sweetalert2';
import useHasPermission from '../../hooks/useHasPermission'

export default function HostsDatatable() {

    const dispatch = useDispatch();
    const canUpdate = useHasPermission("/hosts", "update");
    const canDelete = useHasPermission("/hosts", "delete");
    const [deleteSuccess, setDeleteSuccess] = useState(false);

    const [filtredData, setFiltredData] = useState([]);

    const hostsList = useSelector(state => state.hostsList);
    const { hosts,
        loading,
        error } = hostsList;

    const hostDelete = useSelector(state => state.hostsDelete);
    const { loading: loadingDelete,
        success: successDelete,
        error: errorDelete } = hostDelete;

    const hostsSave = useSelector(state => state.hostsSave);
    const { loading: loadingSave,
        success: successSave,
        error: errorSave } = hostsSave;


    const columns = [
        {
            name: 'Name',
            selector: 'hosts_first_name',
            sortable: true,
            cell: (host) => host.hosts_first_name + " " + host.hosts_last_name
        },
        {
            name: 'Email',
            selector: 'hosts_email',
            sortable: true,
        },
        {
            name: 'Telephone',
            selector: 'hosts_phone',
            sortable: true,
        },
        {
            name: 'City',
            selector: 'hosts_city',
            sortable: true,
        },
        {
            name: 'Property',
            selector: 'hosts_property_name',
            sortable: true,
        },
        {
            name: 'Status',
            selector: 'hosts_status',
            sortable: false,
            cell: (host) =>

                host.hosts_status == 0 ? <div className="card-alert card indigo">
                    <div className="card-content white-text">
                        <p>{'Waiting'}</p>
                    </div>
                </div> :
                    host.hosts_status == 1 ?
                        <div className="card-alert card orange">
                            <div className="card-content white-text">
                                <p>{'Pending'}</p>
                            </div>
                        </div> :
                        host.hosts_status == 2 ?
                            <div className="card-alert card green">
                                <div className="card-content white-text">
                                    <p>{'Approved'}</p>
                                </div>
                            </div> :
                            host.hosts_status == 3 ?
                                <div className="card-alert card red">
                                    <div className="card-content white-text">
                                        <p>{'Rejected'}</p>
                                    </div>
                                </div> :
                                ""
        },
        {
            cell: (host) => canUpdate ? <button className="btn-flat p-0" style={{ width: "100%", height: "100%" }} onClick={(e) => { editHostHandler(host); }}>
                <i className="material-icons">edit</i>
            </button> : undefined,
            ignoreRowClick: true,
            allowOverflow: true,
            button: true,
            width: '50px'
        },
        {
            cell: (host) => canDelete ? <button className="btn-flat p-0" style={{ width: "100%", height: "100%" }} onClick={(e) => { deleteHostHandler(host); }}>
                <i className="material-icons">delete</i>
            </button> : undefined,
            ignoreRowClick: true,
            allowOverflow: true,
            button: true,
            width: '50px'
        }
    ];

    useEffect(() => {

        setFiltredData(hosts);

        if (deleteSuccess) {
            setDeleteSuccess(false);
            dispatch(listHosts());
        }

    }, [successSave, deleteSuccess, hosts]);

    const editHostHandler = (hostData) => {
        dispatch(selectedHost(hostData));
        dispatch(toggleHostsSidebar(true));
    }

    const deleteHostHandler = (hostData) => {
        Swal.fire({
            title: 'Are you sure?',
            //text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            cancelButtonText: 'Cancel',
            confirmButtonText: 'Delete'
        }).then((result) => {
            if (result.isConfirmed) {

                dispatch(deleteHost(hostData, (response) => {
                    setDeleteSuccess(true);
                    Swal.fire(
                        'Delete',
                        'Host successfully deleted.',
                        'success'
                    )
                }));

            }
        })
    }

    const dataFiltre = (e) => {
        let textFiltre = e.target.value;
        let data = hosts.filter(item => item.hosts_name && item.hosts_name.toLowerCase().includes(textFiltre.toLowerCase()));
        setFiltredData(data);
    }

    return <React.Fragment>
        {loading || loadingDelete && <div></div>}
        {error && <div>{error}</div>}
        {errorDelete && <div>{errorDelete}</div>}
        <div className="content-area full-width">
            <h5 className="mb-2 sidebar-title"><i className="material-icons app-header-icon text-top">perm_identity</i> Hosts</h5>
            <div className="app-wrapper">
                <div className="datatable-search">
                    <i className="material-icons mr-2 search-icon">search</i>
                    <input type="text" placeholder="Search" className="app-filter" id="global_filter" onChange={dataFiltre} />
                </div>
                <div id="button-trigger" className="card card card-default scrollspy border-radius-6 fixed-width">
                    <div className="p-0 hosts-table">
                        <DataTable
                            columns={columns}
                            data={filtredData}
                            selectableRows={true}
                            selectableRowsHighlight={true}
                            pagination={true}
                            paginationComponentOptions={{ noRowsPerPage: true }}
                            highlightOnHover={true}
                            noHeader={true}
                            striped={true}
                            theme="solarized"
                        />
                    </div>
                </div>
            </div>
        </div>
    </React.Fragment>;
}

