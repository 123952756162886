export const menuItems = [
    {
        icon: 'people',
        label: 'Hosts',
        to: '/',
        key: 'HOSTS'
    },
    {
        icon: 'people',
        label: 'Guests',
        to: '/users',
        key: 'USERS'
    },
    {
        icon: 'people',
        label: 'Reservations',
        to: '/reservations',
        key: 'RESERVATIONS'
    },
    {
        icon: 'people',
        label: 'Utilisateurs',
        to: '#',
        key: 'USERS_ROOT',
        content: [{
            icon: 'face',
            label: 'Utilisateurs',
            to: '/users',
            key: 'USERS'
        },
        {
            icon: 'verified_user',
            label: 'Les rôles',
            to: '/roles',
            key: 'ROLES'
        },
        {
            icon: 'tune',
            label: 'Les permissions',
            to: '/permissions',
            key: 'PERMISSIONS'
        }

        ],
    },
    {
        icon: 'perm_media',
        label: 'Gestionnaire de fichiers',
        to: '/files-manager',
        key: 'FILE_MANAGER'
    },
    {
        icon: 'security',
        label: 'Applications autorisées',
        to: '/authorized-apps',
        key: 'API'
    }

]