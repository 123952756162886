import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import MenuItems from './Navbar/MenuItems';
import { useSelector, useDispatch } from 'react-redux';
import Skeleton from 'react-loading-skeleton';
export default function SideNav() {

    const dispatch = useDispatch();

    const location = useSelector(state => state.location);

    return (
        <React.Fragment>

            {/* <!-- BEGIN: SideNav--> */}
            <aside className="sidenav-main sidenav-light sidenav-active-square">
                <div className="brand-sidebar">
                    <h1 className="logo-wrapper">
                        <Link className="brand-logo darken-1" to="/">
                            <img className="hide-on-med-and-down" src="/app-assets/images/logo/logo.svg" alt="" />
                            <img className="show-on-medium-and-down hide-on-med-and-up" src="/app-assets/images/logo/logo.svg" alt="" />
                            {/* <span className="logo-text hide-on-med-and-down">Manager</span> */}
                        </Link>
                        {/* <a className="navbar-toggler" href="/"><i className="material-icons">radio_button_checked</i></a> */}
                    </h1>
                </div>

                <MenuItems />

                <div className="navigation-background"></div>
            </aside>
            {/* <!-- END: SideNav--> */}
        </React.Fragment>
    );

}
