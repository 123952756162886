import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
// import { menuItems } from './MenuData';
import { useSelector, useDispatch } from 'react-redux';
import { setSideBarItems } from '../../../actions/sideBarActions'

export default function MenuItems() {

    const { user } = useSelector(state => state.login);

    const location = useSelector(state => state.location);
    const dispatch = useDispatch();

    let menuItems = [];

    const { sideBarItems } = useSelector(state => state.sideBarItems);

    const { loading: userAuthPermissionsLoading, userAuthPermissions } = useSelector(state => state.userAuthPermissions);

    const hasPermissions = (key) => {
        return userAuthPermissions?.find((item) => {
            return item.key === key || item.key === "ALL"
        })
    }

    useEffect(() => {
        let menuItemsArray = [];

        let menuItemsObject = {};

        //Reports
        menuItemsObject = {
            icon: 'insert_chart',
            label: 'Reports',
            to: '/',
            key: 'DASHBOARD',
        }
        menuItemsArray.push(menuItemsObject);

        //reservations
        menuItemsObject = {
            icon: 'group',
            label: 'Reservations',
            to: '/reservations',
            key: 'RESERVATIONS'
        }
        menuItemsArray.push(menuItemsObject);

        //Users
        if (user.token.authUser.roles_name == "SuperAdmin") {
            menuItemsObject = {
                icon: 'people',
                label: 'Users',
                to: '#',
                key: 'USERS_ROOT',
                content: [{
                    icon: 'face',
                    label: 'Users',
                    to: '/users',
                    key: 'USERS'
                },
                {
                    icon: 'verified_user',
                    label: 'Roles',
                    to: '/roles',
                    key: 'ROLES'
                },
                {
                    icon: 'tune',
                    label: 'Permissions',
                    to: '/permissions',
                    key: 'PERMISSIONS'
                }

                ],
            }
            menuItemsArray.push(menuItemsObject);

        } else {
            menuItemsObject = {
                icon: 'face',
                label: 'Users',
                to: '/users',
                key: 'USERS'
            }
            menuItemsArray.push(menuItemsObject);
        }

        //Categories
        if (user.token.authUser.roles_name == "SuperAdmin") {
            menuItemsObject = {
                icon: 'view_list',
                label: 'Categories',
                to: '#',
                key: 'CATEGORIES',
                content: [{
                    icon: 'playlist_add',
                    label: 'Categories',
                    to: '/categories',
                    key: 'CATEGORIES'
                },
                {
                    icon: 'playlist_add',
                    label: 'Categories Items',
                    to: '/category-items',
                    key: 'CATEGORIES_ITEMS'
                }
                ],
            }
            menuItemsArray.push(menuItemsObject);
        }
        //File manager
        menuItemsObject = {
            icon: 'perm_media',
            label: 'File manager',
            to: '/files-manager',
            key: 'FILE_MANAGER'
        }
        menuItemsArray.push(menuItemsObject);

        //Post type
        if (user.token.authUser.roles_name == "SuperAdmin") {
            menuItemsObject = {
                icon: 'art_track',
                label: 'Post type',
                to: '/post-type'
            }
            menuItemsArray.push(menuItemsObject);
        }

        //Post Templates
        if (user.token.authUser.roles_name == "SuperAdmin") {
            menuItemsObject = {
                icon: 'view_compact',
                label: 'Post Templates',
                to: '/post-templates',
                key: 'POST_TEMPLATES'
            }
            menuItemsArray.push(menuItemsObject);
        }

        //Menus
        if (user.token.authUser.roles_name == "SuperAdmin") {
            menuItemsObject = {
                icon: 'menu',
                label: 'Menus',
                to: '#',
                key: 'MENUS_ROOT',
                content: [{
                    icon: 'featured_play_list',
                    label: 'Menus List',
                    to: '/menus',
                    key: 'MENUS'
                },
                {
                    icon: 'format_list_bulleted',
                    label: 'Menu items',
                    to: '/menu-items',
                    key: 'MENU_ITEMS'
                }
                ],
            }
            menuItemsArray.push(menuItemsObject);
        }

        //Translation
        if (user.token.authUser.roles_name == "SuperAdmin") {
            menuItemsObject = {
                icon: 'translate',
                label: 'Translation',
                to: '#',
                key: 'LANGUAGES_ROOT',
                content: [{
                    icon: 'language',
                    label: 'Languages',
                    to: '/languages',
                    key: 'LANGUAGES'
                },
                {
                    icon: 'chrome_reader_mode',
                    label: 'Loco',
                    to: '/loco-translate',
                    key: 'LOCO_TRANSLATE'
                },
                {
                    icon: 'chrome_reader_mode',
                    label: 'Loco BO',
                    to: '/locobo-translate',
                    key: 'LOCOBO_TRANSLATE'
                }
                ],
            }
            menuItemsArray.push(menuItemsObject);
        }

        //Authorized apps
        if (user.token.authUser.roles_name == "SuperAdmin") {
            menuItemsObject = {
                icon: 'security',
                label: 'Authorized apps',
                to: '/authorized-apps',
                key: 'API'
            }
            menuItemsArray.push(menuItemsObject);
        }

    }, []);

    return (
        <React.Fragment>

            <ul className="sidenav sidenav-collapsible leftside-navigation collapsible sidenav-fixed menu-shadow" id="slide-out" data-menu="menu-navigation" data-collapsible="menu-accordion">
                {
                    sideBarItems.map((item, i) => {
                        var parentClass = classNames('waves-effect waves-cyan', {
                            'collapsible-header': item.content,
                            'active': location.path === item.to
                        });
                        return hasPermissions(item.key) ? (
                            <li className="bold" key={'menu-parent' + i}>
                                <Link to={item.to} className={parentClass}>
                                    <i className="material-icons">{item.icon}</i>
                                    <span className="menu-title" data-i18n="Mail">{item.label}</span>
                                </Link>
                                {item.content ? (
                                    <div className="collapsible-body">
                                        <ul className="collapsible collapsible-sub" data-collapsible="accordion">
                                            {
                                                item.content.map((contentItem, i) => {
                                                    return hasPermissions(contentItem.key) ? (
                                                        <li key={'menu-child' + i} className={location.path === contentItem.to ? ("active") : ""}>
                                                            <Link to={contentItem.to} className={location.path === contentItem.to ? ("active") : ""}>
                                                                <i className="material-icons"  >{contentItem.icon}</i>
                                                                <span data-i18n="Modern Menu">{contentItem.label}</span></Link>
                                                        </li>
                                                    ) : ""

                                                })
                                            }

                                        </ul>
                                    </div>
                                ) : ""}
                            </li>
                        ) : ""
                    })
                }
            </ul>
        </React.Fragment>
    );
}
