import React, { useEffect, useState } from 'react'
import { Form, Field, Formik } from 'formik'
import * as Yup from 'yup'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux';
import { forgotPassword } from '../../../actions/authActions'
import Errors from '../../../validations/Errors'
import { toast } from 'react-toastify'
import Skeleton from 'react-loading-skeleton';


const forgotPasswordSchema = Yup.object().shape({
    users_email: Yup.string().email().required(Errors.required({ name: "l'email" })),
})


export default function ForgotPasswordForm() {
    const dispatch = useDispatch();
    const { loading, user, success } = useSelector(state => state.forgotPassword);
    const [sendLinkSuccess, setLinkSuccess] = useState(false);
    const [email, setEmail] = useState('');
    const [sendLinkFail, setLinkFail] = useState({
        state: false,
        message: null
    });


    useEffect(() => {

        if (sendLinkSuccess) {
            toast.success('Nous envoyons un lien de réinitialisation avec succès');
        }
        if (sendLinkFail.state) {
            toast.error(sendLinkFail.message);
            setLinkFail({
                state: false,
                message: null
            });
        }

    }, [sendLinkFail, sendLinkSuccess]);


    return (
        sendLinkSuccess ? (
            <div className="card light-green">
                <div className="card-content white-text">
                    <span className="card-title">Terminé</span>
                    <p>
                        Nous envoyons à votre mail un lien pour réinitialiser votre mot de passe vérifiez votre boîte de réception
                    </p>
                </div>
            </div>
        ) : (

            <Formik
                initialValues={{
                    users_email: email ? email : '',
                }}
                validationSchema={forgotPasswordSchema}
                enableReinitialize={true}
                onSubmit={async (values, { resetForm }) => {
                    await dispatch(forgotPassword(values, (resp) => {
                        if (resp.error) {
                            setLinkFail({
                                state: true,
                                message: resp.message
                            })
                        } else {
                            setLinkSuccess(true);

                        }
                    }))
                }}
            >
                {({ values, errors, touched, isSubmitting }) => (

                    loading ? (
                        <div className="pt-5 pb-5 pl-5 pr-5">
                            <Skeleton count={5} />
                        </div>
                    ) : (
                        <Form className="login-form">
                            <div className="row">
                                <div className="input-field col s12">
                                    <h5 className="ml-4">Mot de passe oublié</h5>
                                    <p className="ml-4">Vous pouvez réinitialiser votre mot de passe</p>
                                </div>
                            </div>
                            <div className="row margin">
                                <div className="input-field col s12">
                                    <Field id="users_email" name="users_email" type="email" className={errors.users_email ? "error validate" : "validate"} />
                                    <label htmlFor="users_email" >Email</label>
                                    {errors.users_email && touched.users_email ? (
                                        <small className="errorTxt2"><div id="cemail-error" className="error">{errors.users_email}</div></small>
                                    ) : null}
                                </div>
                            </div>
                            <div className="row">
                                <div className="input-field col s12">
                                    <button type="submit" className="btn border-round col s12" disabled={isSubmitting}>Valider</button>
                                </div>
                            </div>
                            <div className="row">
                                <div className="input-field col s6 m6 l6 mb-6">
                                    <p className="margin left-align medium-small">
                                        <Link to="/login">Login</Link></p>
                                </div>
                            </div>
                        </Form>
                    )
                )}
            </Formik>
        )
    )
}
