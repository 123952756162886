import React, { useEffect } from 'react';
import { useSelector } from 'react-redux'
import UsersCount from './UsersCount';


export default function UsersFiltre(props) {

    return (
        <React.Fragment>
            <div className="sidebar-left sidebar-fixed">
                <div className="sidebar">
                    <div className="sidebar-content">
                        <div className="sidebar-header">
                            <div className="sidebar-details">
                                <h5 className="m-0 sidebar-title"><i className="material-icons app-header-icon text-top">perm_identity</i> Users</h5>
                                <div className="mt-10 pt-2">
                                    <p className="m-0 subtitle font-weight-700">Users count</p>
                                    <UsersCount />
                                </div>
                            </div>
                        </div>
                        <div id="sidebar-list" className="sidebar-menu list-group position-relative ps ps--active-y">
                        </div>

                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}