import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { listFiles, selectedFile } from '../../actions/fileManagerActions';
import { apiProxy, assetsURL } from '../../config/Settings';
import { headers } from '../../config/ApiHeaders';
import axios from 'axios';
import Swal from 'sweetalert2';

export default function FileManagerInfo(props) {

    const fileSelected = useSelector(state => state.selectedFile);

    const dispatch = useDispatch();

    const closeDetails = () => {
        dispatch(selectedFile(null));
    }

    const deleteFile = () => {

        Swal.fire({
            title: 'Are you sure?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            cancelButtonText: 'Cancel',
            confirmButtonText: 'Delete'
        }).then((result) => {
            if (result.isConfirmed) {

                axios.delete(apiProxy + "/api/files_manager/",
                    {
                        headers: headers,
                        data: {
                            path: fileSelected.file.path
                        }
                    })
                    .then((res) => {
                        if (res.status === 200) {
                            dispatch(listFiles());
                            closeDetails();
                            Swal.fire(
                                'Delete',
                                'File successfully deleted',
                                'success'
                            )
                        } else {
                            console.log('error');
                        }
                    });
            }
        })

    }

    const archiveFile = () => {

        Swal.fire({
            title: 'Are you sure?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            cancelButtonText: 'Cancel',
            confirmButtonText: 'Delete'
        }).then((result) => {
            if (result.isConfirmed) {

                axios.patch(apiProxy + "/api/files_manager/", {
                    path: fileSelected.file.path
                }, { headers: headers })
                    .then((res) => {
                        if (res.status === 200) {
                            dispatch(listFiles());
                            closeDetails();
                            Swal.fire(
                                'Delete',
                                'File successfully deleted',
                                'success'
                            )
                        } else {
                            console.log('error');
                        }
                    });
            }
        })

    }

    const restoreFile = () => {
        axios.patch(apiProxy + "/api/files_manager/restore", {
            path: fileSelected.file.path
        }, { headers: headers })
            .then((res) => {
                if (res.status === 200) {
                    dispatch(listFiles());
                    closeDetails();
                } else {
                    console.log('error');
                }
            });
    }

    useEffect(() => {

    }, [fileSelected]);

    return <React.Fragment>
        <div className={fileSelected.file ? 'app-file-sidebar-info show' : 'app-file-sidebar-info'}>
            {fileSelected.file ? (
                <div className="card box-shadow-none m-0 pb-1">
                    <div className="card-header display-flex justify-content-between align-items-center">
                        <h6 className="m-0">{fileSelected.file.fileName}</h6>
                        <div className="app-file-action-icons display-flex align-items-center">
                            {props.filtre === "trash" ? (
                                <React.Fragment>
                                    <i className="material-icons mr-10" onClick={deleteFile}>delete</i>
                                    <i className="material-icons mr-10" onClick={restoreFile}>restore</i>
                                </React.Fragment>
                            )
                                : <i className="material-icons mr-10" onClick={archiveFile}>delete</i>
                            }

                            <i className="material-icons close-icon" onClick={closeDetails} >close</i>
                        </div>
                    </div>

                    <div className="card-content">
                        <div className="file-details" id="details">
                            <div className="display-flex align-items-center flex-column pb-2 pt-4">
                                {
                                    fileSelected.file.extension !== 'jpg' && fileSelected.file.extension !== 'png' && fileSelected.file.extension !== 'jpeg' ?
                                        (
                                            fileSelected.file.extension === 'pdf' ?
                                                <img className="recent-file" src="/app-assets/images/icon/pdf.png" height="38" width="30" alt="pdf" />
                                                : fileSelected.file.extension === 'doc' || fileSelected.file.extension === 'docx' ?
                                                    <img className="recent-file" src="/app-assets/images/icon/doc-image.png" height="38" width="30" alt="doc" />
                                                    : <img className="recent-file" src="/app-assets/images/icon/doc.png" height="38" width="30" alt="???" />
                                        )
                                        : <img src={assetsURL + fileSelected.file.path} alt="" className="mt-5 mb-5 responsive-img" />
                                }

                                <p className="mt-4">{fileSelected.file.size}</p>
                            </div>
                            <div className="divider mt-5 mb-5"></div>
                            <div className="pt-6">
                                <span className="app-file-label">{'Info'}</span>
                                <div className="display-flex justify-content-between align-items-center mt-6">
                                    <p>{'Type'}</p>
                                    <p className="font-weight-700">{fileSelected.file.extension}</p>
                                </div>
                                <div className="display-flex justify-content-between align-items-center mt-6">
                                    <p>{'Size'}</p>
                                    <p className="font-weight-700">{fileSelected.file.size}</p>
                                </div>
                                <div className="display-flex justify-content-between align-items-center mt-6">
                                    <p>{'Created'}</p>
                                    <p className="font-weight-700">{fileSelected.file.create_date}</p>
                                </div>
                                <div className="display-flex justify-content-between align-items-center mt-6">
                                    <p>Url</p>
                                    <p className="font-weight-700">
                                        <a href={'/' + fileSelected.file.path} target="_blank">{fileSelected.file.path}</a>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            ) : null}
        </div>
    </React.Fragment>
}
