export const customStyles = {
    menu: (provided, state) => ({
        ...provided,
        zIndex: 999,
    }),
    valueContainer: (provided, state) => ({
        ...provided,
        maxHeight: 35,
    }),
    control: (provided, state) => ({
        ...provided,
        maxHeight: 35,
    }),
    container: (provided, state) => ({
        ...provided,
        marginTop: 15,
    }),
    singleValue: (provided, state) => ({
        ...provided,
        // top: "60%",
        color: "#fff",
    }),
    input: (provided, state) => ({
        ...provided,
        maxHeight: 35,
        overflow: "hidden"
    }),
    option: (provided, state) => ({
        ...provided,
        color: state.isSelected ? "#000" : "#fff",
        backgroundColor: state.isSelected ? "#F3EFEA" : "",
        ":hover": {
            backgroundColor: "#F3EFEA",
            color: "#000"
        }
    }),


}