import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'

export default function RolesCount(props) {


    const rolesList = useSelector(state => state.rolesList);
    const { roles,
        loading,
        error } = rolesList;

    useEffect(() => {

    }, [roles]);

    return (
        <React.Fragment>
            {loading ? <div></div> :
                error ? <div>{error}</div> :

                    <p className="m-0 text-muted">{roles.length} Roles</p>
            }
        </React.Fragment>
    );
}