import React from 'react';
import PermissionsCount from './PermissionsCount';
import { useSelector, useDispatch } from 'react-redux';

export default function PermissionsFiltre(props) {

    return (
        <React.Fragment>
            {/* <!-- Sidebar Area Starts --> */}
            <div className="sidebar-left sidebar-fixed">
                <div className="sidebar">
                    <div className="sidebar-content">
                        <div className="sidebar-header">
                            <div className="sidebar-details">
                                <h5 className="m-0 sidebar-title"><i className="material-icons app-header-icon text-top">tune</i> {'Permissions'}
                                </h5>
                                <div className="mt-10 pt-2">
                                    <p className="m-0 subtitle font-weight-700">{'Count Permissions'}</p>
                                    <PermissionsCount />
                                </div>
                            </div>
                        </div>
                        <div id="sidebar-list" className="sidebar-menu list-group position-relative ps ps--active-y">
                        </div>

                    </div>
                </div>
            </div>
            {/* <!-- Sidebar Area Ends --> */}
        </React.Fragment>
    );
}