import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Form, Field, Formik } from 'formik';
import * as yup from 'yup';
import Errors from '../../validations/Errors';
import { toast } from 'react-toastify';
import { listAuthorizedApps, saveAuthorizedApp, selectedAuthorizedApp, toggleAuthorizedAppsSidebar } from '../../actions/authorizedAppsActions';
import Loading from '../loader/Loading'

export default function AuthorizedAppsFormSidebar(props) {

    function validateHost(value) {
        let error;
        if (!value) {
            error = Errors.required({ name: "Le nom de domaine" });
        } else if (!/^(?!:\/\/)([a-zA-Z0-9-_]+\.)*[a-zA-Z0-9][a-zA-Z0-9-_]+\.[a-zA-Z]{2,11}?$/i.test(value)) {
            error = Errors.domain();
        }
        return error;
    }


    const CreateAuthorizedAppsSchema = yup.object().noUnknown().shape({
        name: yup.string().required(Errors.required({ name: 'The name' })),
    })

    const dispatch = useDispatch();

    const [saveSuccess, setSaveSuccess] = useState(false);
    const [saveFail, setSaveFail] = useState({
        state: false,
        message: null
    });

    const [id, setId] = useState('');
    const [name, setName] = useState('');
    const [host, setHost] = useState('');
    const [isAllowed, setIsAllowed] = useState(false);
    const { loading: userAuthPermissionsLoading, userAuthPermissions } = useSelector(state => state.userAuthPermissions);
    const toggleAhutorizedAppsSidebar = useSelector(state => state.toggleAhutorizedAppsSidebar);

    const selectedAhutorizedApp = useSelector(state => state.selectedAhutorizedApp);

    const { loading: loadingSave, success: successSave, error: errorSave } = useSelector(state => state.authorizedAppsSave);

    useEffect(() => {
        if (selectedAhutorizedApp.authorizedApp) {
            setId(selectedAhutorizedApp.authorizedApp.id);
            setName(selectedAhutorizedApp.authorizedApp.name);
            setHost(selectedAhutorizedApp.authorizedApp.host);
            setIsAllowed(selectedAhutorizedApp.authorizedApp.is_allowed);
        } else {
            setId('');
            setHost('');
            setName('');
            setIsAllowed(false);
        }



        if (saveSuccess) {
            setSaveSuccess(false);
            dispatch(listAuthorizedApps());
            toast.success('Application saved');
        }
        if (saveFail.state) {
            toast.error(saveFail.message);
            dispatch(listAuthorizedApps());
            setSaveFail({
                state: false,
                message: null
            });
        }

    }, [selectedAhutorizedApp, saveSuccess, saveFail]);


    const closeFormSidebar = () => {
        dispatch(selectedAuthorizedApp(null));
        dispatch(toggleAuthorizedAppsSidebar(false));
    }


    return <React.Fragment>
        {loadingSave && <div><Loading /></div>}
        {/* {errorSave && <div>{errorSave}</div> } */}

        <div className={toggleAhutorizedAppsSidebar.toggle ? "contact-compose-sidebar show" : "contact-compose-sidebar"}>
            <div className="card quill-wrapper">
                <div className="card-content pt-0">
                    <div className="card-header display-flex pb-2">
                        <h3 className="card-title contact-title-label">{id === '' ? "Create a new app" : "Update app"}</h3>
                        <div className="close close-icon" onClick={closeFormSidebar}>
                            <i className="material-icons">close</i>
                        </div>
                    </div>
                    <div className="divider"></div>

                    <Formik
                        initialValues={{
                            id: id ? id : '',
                            name: name ? name : '',
                            host: host ? host : '',
                            is_allowed: isAllowed ? true : false,
                        }}
                        validationSchema={CreateAuthorizedAppsSchema}
                        enableReinitialize={true}
                        onSubmit={async (values, { resetForm }) => {
                            dispatch(saveAuthorizedApp(values, (response) => {
                                response.error ? setSaveFail({
                                    state: true,
                                    message: response.message
                                }) : setSaveSuccess(true);
                            }));
                            closeFormSidebar();
                            resetForm({ values: '' });
                        }}
                    >
                        {({ values, errors, touched, isSubmitting }) => (
                            <Form className="edit-contact-item mb-5 mt-5">
                                <div className="row">

                                    <div className="input-field col s12">
                                        <Field id="name" name="name" type="text" className={errors.name ? "error validate" : "validate"} />
                                        <label htmlFor="name" className={name !== '' ? 'active' : ''}>{"App Name"}</label>
                                        {errors.name && touched.name ? (
                                            <small className="errorTxt2"><div id="cemail-error" className="error">{errors.name}</div></small>
                                        ) : null}
                                    </div>

                                    <div className="input-field col s12">
                                        <Field id="host" name="host" validate={validateHost} type="text" className={errors.host ? "error validate" : "validate"} />
                                        <label htmlFor="host" className={host !== '' ? 'active' : ''}>{"Domain name"}</label>
                                        {errors.host && touched.host ? (
                                            <small className="errorTxt2"><div id="cemail-error" className="error">{errors.host}</div></small>
                                        ) : null}
                                    </div>

                                    <div className="input-field col s12">
                                        <p className="mb-2">{"Authoze"}</p>
                                        <div className="switch">
                                            <label>
                                                {"No"}
                                                <Field name="is_allowed" checked={values.is_allowed} type="checkbox" />
                                                <span className="lever"></span>
                                                {"Yes"}
                                            </label>
                                        </div>
                                    </div>

                                </div>

                                <div className="card-action pl-0 pr-0 right-align">
                                    {id === '' ? (
                                        <button type="submit" className="btn-small waves-effect waves-light add-contact" disabled={isSubmitting}>
                                            <span>{"Add app"}</span>
                                        </button>

                                    ) : (
                                        <button type="submit" className="btn-small waves-effect waves-light amber darken-4 update-contact" disabled={isSubmitting}>
                                            <span>{"Edit app"}</span>
                                        </button>
                                    )}
                                </div>

                            </Form>
                        )}

                    </Formik>

                </div>
            </div>
        </div>
    </React.Fragment>;
}