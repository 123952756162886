import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Form, Field, Formik, FieldArray } from 'formik'

import * as Yup from 'yup';
import Errors from '../../validations/Errors';
import { toast } from 'react-toastify';
import { listReservations, saveReservation, deleteReservation, selectedReservation, toggleReservationsSidebar } from '../../actions/reservationsActions';
import { userAuthPermissions } from '../../actions/usersActions';

import moment from 'moment';

import Select from 'react-select'
import { customStyles } from '../../styles/reactSelect';

export default function FormComponent() {

    // const CreateReservationsSchema = Yup.object().shape({
    //     name: Yup.string().min(3, Errors.min({ name: 'Name', number: "3" })).required(Errors.required({ name: 'Name' }))
    // })

    const dispatch = useDispatch();

    const [id, setId] = useState('');

    const [status, setStatus] = useState(0);

    const [selectAll, setSelectAll] = useState(false);

    const [allCheckboxes, setCheckboxes] = useState([]);

    const [saveSuccess, setSaveSuccess] = useState(false);

    const reservationsToggleSidebar = useSelector(state => state.toggleReservationsSidebar);

    const reservationSelected = useSelector(state => state.selectedReservation);

    const reservationsSave = useSelector(state => state.reservationsSave);

    const statusOptions = [
        {
            label: "Waiting",
            value: 0
        },
        {
            label: "Send to Host",
            value: 1
        },
        {
            label: "Approved Admin",
            value: 2
        },
        {
            label: "Rejected Admin",
            value: 3
        }
    ];

    const { loading: loadingSave,
        success: successSave,
        error: errorSave } = reservationsSave;


    useEffect(() => {

        console.log("reservationSelected", reservationSelected);

        if (reservationSelected.reservation) {
            setId(reservationSelected.reservation.id);
            setStatus(reservationSelected.reservation.status);
        } else {
            setId('');
            setStatus(0);
        }

        if (saveSuccess) {
            setSaveSuccess(false);
            dispatch(listReservations());
            toast.success('Reservation Saved');
        }

    }, [reservationSelected, saveSuccess]);

    const closeFormSidebar = () => {
        dispatch(selectedReservation(null));
        dispatch(toggleReservationsSidebar(false));
    }

    const handleSubmit = (values) => {
        dispatch(saveReservation(values, (response) => {
            dispatch(userAuthPermissions());
            setSaveSuccess(true);
            closeFormSidebar();
        }));
    }

    const selectAllHandler = () => {
        let checkboxes = allCheckboxes;
        for (let i = 0; i < checkboxes.length; i++) {
            checkboxes[i] = !selectAll;
        }
        setCheckboxes(checkboxes);
        setSelectAll(!selectAll);
    }


    return <React.Fragment>
        {loadingSave && <div></div>}
        {errorSave && <div>{errorSave}</div>}
        <div className={reservationsToggleSidebar.toggle ? "contact-compose-sidebar show" : "contact-compose-sidebar"}>
            <div className="card quill-wrapper">
                <div className="card-content pt-0">
                    <div className="card-header display-flex pb-2">
                        <h3 className="card-title reservation-title-label">{'Reservation detail'}</h3>
                        <div className="close close-icon" onClick={closeFormSidebar}>
                            <i className="material-icons">close</i>
                        </div>
                    </div>
                    <div className="divider"></div>

                    <Formik
                        initialValues={{
                            id: id ? id : '',
                            status: status ? status : '',
                            property: reservationSelected.reservation ? reservationSelected.reservation.property : '',
                            propertyId: reservationSelected.reservation ? reservationSelected.reservation.property_id : '',
                        }}
                        // validationSchema={CreateReservationsSchema}
                        enableReinitialize={true}
                        onSubmit={async (values, { resetForm }) => {
                            values = { ...values };
                            setSelectAll(false);
                            handleSubmit(values);
                            resetForm({ values: '' });
                        }}
                    >
                        {({ values, errors, touched, isSubmitting, setFieldValue, setFieldTouched }) => (
                            <Form className="edit-reservation-item mb-5 mt-5">
                                <div className="row">
                                    <div className="input-field col s12">
                                        <Select
                                            name="status"
                                            id="status"
                                            onBlur={() => setFieldTouched("status", true)}
                                            value={statusOptions.filter(opt => { return (opt.value === status) })}
                                            onChange={(opt, e) => {
                                                setStatus(opt?.value);
                                                setFieldValue("status", opt?.value);
                                            }}
                                            blurInputOnSelect={true}
                                            placeholder=""
                                            isSearchable={false}
                                            isClearable={false}
                                            styles={customStyles}
                                            options={statusOptions}
                                            error={errors.status}
                                            touched={touched.status}
                                        />
                                    </div>
                                </div>

                                {reservationSelected.reservation ?
                                    <table>
                                        <tbody>
                                            <tr>
                                                <td>Product</td>
                                                <td>{reservationSelected.reservation.property}</td>
                                            </tr>
                                            <tr>
                                                <td>Arrival Date</td>
                                                <td>{moment(reservationSelected.reservation.date_start).format('YYYY-MM-DD')}</td>
                                            </tr>
                                            <tr>
                                                <td>Depart Date</td>
                                                <td>{moment(reservationSelected.reservation.date_end).format('YYYY-MM-DD')}</td>
                                            </tr>
                                            <tr>
                                                <td>Guest Name</td>
                                                <td>{reservationSelected.reservation.guest_name + ' ' + reservationSelected.reservation.guest_last_name}</td>
                                            </tr>
                                            <tr>
                                                <td>Email</td>
                                                <td>
                                                    <a href="mailto:{reservationSelected.reservation.guest_email}">
                                                        {reservationSelected.reservation.guest_email}
                                                    </a>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>Phone</td>
                                                <td>{reservationSelected.reservation.guest_phone}</td>
                                            </tr>
                                            <tr>
                                                <td>Price</td>
                                                <td>{reservationSelected.reservation.price} €</td>
                                            </tr>

                                        </tbody>
                                    </table>
                                    : null}

                                <div className="card-action pl-0 pr-0 right-align">
                                    <button type="submit" className="btn-small waves-effect waves-light add-reservation" disabled={isSubmitting}>
                                        <span>{'Save'}</span>
                                    </button>
                                </div>

                            </Form>
                        )}

                    </Formik>

                </div>
            </div>
        </div>
    </React.Fragment>
}