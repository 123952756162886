import React, { useEffect, useMemo, useState } from 'react';
import DataTable from 'react-data-table-component';
import { useSelector, useDispatch } from 'react-redux';
import { listReservations, saveReservation, deleteReservation, selectedReservation, toggleReservationsSidebar } from '../../actions/reservationsActions';
import Swal from 'sweetalert2';
import useHasPermission from '../../hooks/useHasPermission'

import moment from 'moment';

export default function ReservationsDatatable() {

    const dispatch = useDispatch();

    const [deleteSuccess, setDeleteSuccess] = useState(false);

    const [filtredData, setFiltredData] = useState([]);

    const reservationsList = useSelector(state => state.reservationsList);
    const { reservations,
        loading,
        error } = reservationsList;
    const canUpdate = useHasPermission("/reservations", "update");
    const canDelete = useHasPermission("/reservations", "delete");
    const reservationDelete = useSelector(state => state.reservationsDelete);
    const { loading: loadingDelete,
        success: successDelete,
        error: errorDelete } = reservationDelete;

    const reservationsSave = useSelector(state => state.reservationsSave);
    const { loading: loadingSave,
        success: successSave,
        error: errorSave } = reservationsSave;

    const columns = [
        {
            name: 'Product',
            selector: 'product',
            sortable: false,
            format: (row) => row.property,
        },
        {
            name: 'Arrival_date',
            selector: 'date_start',
            sortable: true,
            format: (row) => moment(row.date_start).format('DD/MM/YYYY'),
        },
        {
            name: 'Depart_date',
            selector: 'date_end',
            sortable: false,
            format: (row) => moment(row.date_end).format('DD/MM/YYYY'),
        },
        {
            name: 'Guest Name',
            selector: 'guest_name',
            sortable: false,
            format: (row) => row.guest_name + ' ' + row.guest_last_name,
        },
        {
            name: 'Email',
            selector: 'email',
            sortable: false,
            format: (row) => row.guest_email,
        },
        {
            name: 'Status',
            selector: 'status',
            sortable: false,
            cell: (row) =>

                row.status == 0 ? <div className="card-alert card indigo">
                    <div className="card-content white-text">
                        <p>{'Waiting'}</p>
                    </div>
                </div> :
                    row.status == 1 ?
                        <div className="card-alert card orange">
                            <div className="card-content white-text">
                                <p>{'Pending'}</p>
                            </div>
                        </div> :
                        row.status == 2 ?
                            <div className="card-alert card green">
                                <div className="card-content white-text">
                                    <p>{'Approved'}</p>
                                </div>
                            </div> :
                            row.status == 3 ?
                                <div className="card-alert card red">
                                    <div className="card-content white-text">
                                        <p>{'Rejected'}</p>
                                    </div>
                                </div> :
                                ""
        },
        {
            cell: (reservation) => canUpdate ? <button className="btn-flat p-0" style={{ width: "100%", height: "100%" }} onClick={(e) => { editReservationsHandler(reservation); }}>
                <i className="material-icons">edit</i>
            </button> : undefined,
            ignoreRowClick: true,
            allowOverflow: true,
            button: true,
            width: '50px'
        },
        {
            cell: (reservation) => canDelete ? <button className="btn-flat p-0" style={{ width: "100%", height: "100%" }} onClick={(e) => { deleteReservationsHandler(reservation); }}>
                <i className="material-icons">delete</i>
            </button> : undefined,
            ignoreRowClick: true,
            allowOverflow: true,
            button: true,
            width: '50px'
        }
    ];

    useEffect(() => {

        setFiltredData(reservations);

        if (deleteSuccess) {
            setDeleteSuccess(false);
            dispatch(listReservations());
        }

    }, [successSave, deleteSuccess, reservations]);


    const editReservationsHandler = (reservationData) => {
        dispatch(selectedReservation(reservationData));
        dispatch(toggleReservationsSidebar(true));
    }

    const deleteReservationsHandler = (reservationData) => {
        Swal.fire({
            title: 'Are you sure?',
            icon: 'warning',
            showCancelButton: true,
            cancelButtonColor: '#3085d6',
            confirmButtonColor: '#d33',
            cancelButtonText: 'Cancel',
            confirmButtonText: 'Delete'
        }).then((result) => {
            if (result.isConfirmed) {

                dispatch(deleteReservation(reservationData, (response) => {
                    setDeleteSuccess(true);
                    Swal.fire(
                        'Delete',
                        'Reservation successfully deleted',
                        'success'
                    )
                }));

            }
        })
    }

    const dataFiltre = (e) => {
        let textFiltre = e.target.value;
        let data = reservations.filter(item => item.name && item.name.toLowerCase().includes(textFiltre.toLowerCase()));
        setFiltredData(data);
    }

    return <React.Fragment>
        {loading || loadingDelete && <div></div>}
        {error && <div>{error}</div>}
        {errorDelete && <div>{errorDelete}</div>}
        <div className="content-area content-right">
            <div className="app-wrapper">
                <div className="datatable-search">
                    <i className="material-icons mr-2 search-icon">search</i>
                    <input type="text" placeholder="Search" className="app-filter" id="global_filter" onChange={dataFiltre} />
                </div>
                <div id="button-trigger" className="card card card-default scrollspy border-radius-6 fixed-width">
                    <div className="p-0 users-table">
                        <DataTable
                            columns={columns}
                            data={filtredData}
                            selectableRows={true}
                            selectableRowsHighlight={true}
                            pagination={true}
                            paginationComponentOptions={{ noRowsPerPage: true }}
                            highlightOnHover={true}
                            noHeader={true}
                            striped={true}
                            theme="solarized"
                        />
                    </div>
                </div>
            </div>
        </div>
    </React.Fragment>;
}

