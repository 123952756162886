import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Form, Field, Formik } from 'formik';
import * as Yup from 'yup';
import Errors from '../../validations/Errors';
import { toast } from 'react-toastify';
import { listHosts, saveHost, selectedHost, toggleHostsSidebar, saveHostExtras, saveHostVersements, deleteHostVersement } from '../../actions/hostsActions';
import { toggleFilesManagerModal, selectedFile } from '../../actions/fileManagerActions';
import Select from 'react-select'
import { customStyles } from '../../styles/reactSelect';
import Swal from 'sweetalert2';

export default function HostsFormSideBar(props) {

    const HostsSchema = Yup.object().shape({
        hosts_first_name: Yup.string().required(Errors.required({ name: 'First name' })),
        hosts_last_name: Yup.string().required(Errors.required({ name: 'Last name' })),
        hosts_email: Yup.string().email().required(Errors.required({ name: 'The Email' }))
    })

    const dispatch = useDispatch();

    const [saveSuccess, setSaveSuccess] = useState(false);
    const [saveFail, setSaveFail] = useState({
        state: false,
        message: null
    });

    const [id, setId] = useState(null);
    const [gender, setGender] = useState(1);
    const [lang, setLang] = useState(1);
    const [first_name, setFirstName] = useState('');
    const [last_name, setLastName] = useState('');
    const [identification, setIdentification] = useState('');
    const [phone, setPhone] = useState('');
    const [country, setCountry] = useState('');
    const [city, setCity] = useState('');
    const [zip, setZip] = useState('');
    const [adresse, setAdresse] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [status, setStatus] = useState(0);
    const [property_id, setPropertyId] = useState('');
    const [property_name, setPropertyName] = useState('');


    const genderOptions = [
        {
            label: "Monsieur",
            value: 1
        },
        {
            label: "Madame",
            value: 2
        }
    ];

    const [modeVersementOptions, setModeVersementOptions] = useState([]);

    const statusOptions = [
        {
            label: "Waiting",
            value: 0
        },
        {
            label: "Pending",
            value: 1
        },
        {
            label: "Approved",
            value: 2
        },
        {
            label: "Rejected",
            value: 3
        }
    ];

    const langsOptions = [
        {
            label: "Fançais",
            value: 1
        },
        {
            label: "Englais",
            value: 2
        },
    ];

    const hostsToggleSidebar = useSelector(state => state.toggleHostsSidebar);

    const hostsSave = useSelector(state => state.hostsSave);
    const { loading: loadingSave,
        success: successSave,
        error: errorSave } = hostsSave;

    const fileSelected = useSelector(state => state.selectedFile);

    const selectedHost = useSelector(state => state.selectedHost);
    const [hostSelected, setHostSelected] = useState(selectedHost);
    useEffect(() => {
        setHostSelected(selectedHost);
    }, [selectedHost]);

    const hostsList = useSelector(state => state.hostsList);
    const { hosts,
        loading,
        error } = hostsList;

    useEffect(() => {

        if (hostSelected) {
            if (hostSelected.host) {
                setId(hostSelected.host.id);
                setGender(hostSelected.host.hosts_gender);
                setLang(hostSelected.host.hosts_lang);
                setFirstName(hostSelected.host.hosts_first_name);
                setLastName(hostSelected.host.hosts_last_name);
                setIdentification(hostSelected.host.hosts_identification);
                setPhone(hostSelected.host.hosts_phone);
                setCountry(hostSelected.host.hosts_country);
                setCity(hostSelected.host.hosts_city);
                setZip(hostSelected.host.hosts_zip);
                setAdresse(hostSelected.host.hosts_adresse);
                setEmail(hostSelected.host.hosts_email);
                setPassword(hostSelected.host.hosts_password);
                setStatus(hostSelected.host.hosts_status);
                setPropertyId(hostSelected.host.hosts_property_id);
                setPropertyName(hostSelected.host.hosts_property_name);

            } else {
                setId(null);
                setGender(1);
                setFirstName('');
                setLastName('');
                setIdentification('');
                setPhone('');
                setCountry('');
                setCity('');
                setZip('');
                setAdresse('');
                setEmail('');
                setPassword('');
                setStatus(0);
                setPropertyId('');
                setPropertyName('');
            }
        }

        if (saveSuccess) {
            setSaveSuccess(false);
            dispatch(listHosts());
            toast.success('Host Saved Successfully');
        }
        if (saveFail.state) {
            toast.error(saveFail.message);
            dispatch(listHosts());
            setSaveFail({
                state: false,
                message: null
            });
        }

    }, [hostSelected, saveSuccess, saveFail]);


    const handleSubmit = (values) => {

        dispatch(saveHost(values, (response) => {
            response.error ? setSaveFail({
                state: true,
                message: response.message
            }) : setSaveSuccess(true);
        }));
    }

    /* Extras */

    const [saveExtrasSuccess, setSaveExtrasSuccess] = useState(false);
    const [saveExtrasFail, setSaveExtrasFail] = useState({
        state: false,
        message: null
    });

    const [basePrice, setBasePrice] = useState('');
    const [moyennePrice, setMoyennePrice] = useState('');
    const [hautePrice, setHautePrice] = useState('');
    const [specialPrice, setSpecialPrice] = useState('');
    const [minNights, setMinNights] = useState('');
    const [hosts_ical, setHostsIcal] = useState('');

    useEffect(() => {

        if (hostSelected) {
            if (hostSelected.host) {
                setBasePrice(hostSelected.host.hosts_base_price);
                setMoyennePrice(hostSelected.host.hosts_moyenne_price);
                setHautePrice(hostSelected.host.hosts_haute_price);
                setSpecialPrice(hostSelected.host.hosts_special_price);
                setMinNights(hostSelected.host.hosts_min_nights);
                setHostsIcal(hostSelected.host.hosts_ical);

                const modeVersement = hostSelected.host.hosts_mode_versement.map((item, index) => {
                    return {
                        label: item.versement_last_name + '- IBAN: ****' + item.versement_iban.substr(item.versement_iban.length - 4),
                        value: item.id
                    }
                });

                setModeVersementOptions(modeVersement);

            } else {
                setBasePrice('');
                setMoyennePrice('');
                setHautePrice('');
                setSpecialPrice('');
                setMinNights('');
                setHostsIcal('');
            }
        }

        if (saveExtrasSuccess) {
            setSaveExtrasSuccess(false);
            toast.success('Saved Successfully');
            dispatch(listHosts());
        }
        if (saveExtrasFail.state) {
            toast.error(saveExtrasFail.message);
            setSaveExtrasFail({
                state: false,
                message: null
            });
            dispatch(listHosts());
        }

    }, [hostSelected, saveExtrasSuccess, saveExtrasFail]);

    const handleExtrasSubmit = (values) => {

        dispatch(saveHostExtras(values, (response) => {
            response.error ? setSaveExtrasFail({
                state: true,
                message: response.message
            }) : setSaveExtrasSuccess(true);
        }));

    }

    /* Versements */

    const [saveVersementsSuccess, setSaveVersementsSuccess] = useState(false);

    const [saveVersementsFail, setSaveVersementsFail] = useState({
        state: false,
        message: null
    });


    useEffect(() => {

        if (saveVersementsSuccess) {
            setSaveVersementsSuccess(false);
            toast.success('Saved Successfully');
            dispatch(listHosts());
            setHostSelected(selectedHost);
        }
        if (saveVersementsFail.state) {
            toast.error(saveVersementsFail.message);
            setSaveVersementsFail({
                state: false,
                message: null
            });
        }

    }, [selectedHost, saveVersementsSuccess, saveVersementsFail]);

    useEffect(() => {

        if (hosts && hosts.length > 0) {
            let host = hosts.filter(host => host.id === id);

            if (host.length > 0) {
                setHostSelected({
                    host: host[0]
                });
            }
        }

    }, [hosts]);

    const handleVersementSubmit = (values) => {

        dispatch(saveHostVersements(values, (response) => {
            response.error ? setSaveVersementsFail({
                state: true,
                message: response.message
            }) : setSaveVersementsSuccess(true);
        }));

    }

    const [deleteVersementSuccess, setDeleteVersementSuccess] = useState(false);

    useEffect(() => {

        if (deleteVersementSuccess) {
            setDeleteVersementSuccess(false);
            dispatch(listHosts());
            setHostSelected(selectedHost);

            console.log('deleteVersementSuccess', deleteVersementSuccess);
        }

    }, [deleteVersementSuccess]);

    const HandlerVersementDelete = (versement_id) => {
        Swal.fire({
            title: 'Are you sure?',
            //text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            cancelButtonText: 'Cancel',
            confirmButtonText: 'Delete'
        }).then((result) => {
            if (result.isConfirmed) {

                dispatch(deleteHostVersement(versement_id, (response) => {
                    Swal.fire(
                        'Delete',
                        'versement successfully deleted.',
                        'success'
                    )
                }));

                setDeleteVersementSuccess(true);

            }
        })
    }

    const closeFormSidebar = () => {
        setHostSelected(null);
        dispatch(toggleHostsSidebar(false));
        dispatch(selectedFile(null));
    }

    return <React.Fragment>
        {loadingSave && <div></div>}
        {errorSave && <div>{errorSave}</div>}

        <div className={hostsToggleSidebar.toggle ? "contact-compose-sidebar sidebar-l show" : "contact-compose-sidebar sidebar-l"}>
            <div className="card quill-wrapper">
                <div className="card-content pt-0">
                    <div className="card-header display-flex pb-2">
                        <h3 className="card-title contact-title-label">{!id ? 'Create New Host' : 'Update Host'}</h3>
                        <div className="close close-icon" onClick={closeFormSidebar}>
                            <i className="material-icons">close</i>
                        </div>
                    </div>
                    <div className="divider"></div>

                    {/* sidebar tabs */}
                    <ul className="tabs mb-2">
                        <li className="tab">
                            <a className="display-flex align-items-center active" id="account-tab" href="#account">
                                <span>{'Information'}</span>
                            </a>
                        </li>
                        <li className="tab">
                            <a className="display-flex align-items-center" id="information-tab" href="#Extras">
                                <span>{'Info Extras'}</span>
                            </a>
                        </li>
                        <li className="tab">
                            <a className="display-flex align-items-center" id="information-tab" href="#versements">
                                <span>{'Versements'}</span>
                            </a>
                        </li>
                        <li className="tab">
                            <a className="display-flex align-items-center" id="information-tab" href="#mode_versement">
                                <span>{'mode de versements'}</span>
                            </a>
                        </li>
                        <li className="tab">
                            <a className="display-flex align-items-center" id="information-tab" href="#icals">
                                <span>{'Icals'}</span>
                            </a>
                        </li>
                    </ul>
                    <div className="row tab-content">
                        <div className="col s12" id="account">
                            <Formik
                                initialValues={{
                                    id: id ? id : '',
                                    hosts_gender: gender ? gender : '',
                                    hosts_lang: lang ? lang : 1,
                                    hosts_first_name: first_name ? first_name : '',
                                    hosts_last_name: last_name ? last_name : '',
                                    hosts_email: email ? email : '',
                                    hosts_identification: identification ? identification : '',
                                    hosts_phone: phone ? phone : '',
                                    hosts_country: country ? country : '',
                                    hosts_city: city ? city : '',
                                    hosts_zip: zip ? zip : '',
                                    hosts_adresse: adresse ? adresse : '',
                                    hosts_password: '',
                                    hosts_status: status ? status : '',
                                }}
                                validationSchema={HostsSchema}
                                enableReinitialize={true}
                                onSubmit={async (values, { resetForm }) => {
                                    closeFormSidebar();
                                    handleSubmit(values);
                                    resetForm({ values: '' });
                                }}
                            >
                                {({ values, errors, touched, isSubmitting, setFieldValue, setFieldTouched }) => (
                                    <Form className="edit-contact-item mb-5">
                                        <div className="row">

                                            <div className="input-field col s4">
                                                <Select
                                                    name="hosts_gender"
                                                    id="hosts_gender"
                                                    onBlur={() => setFieldTouched("hosts_gender", true)}
                                                    value={genderOptions.filter(opt => { return (opt.value === gender) })}
                                                    onChange={(opt, e) => {
                                                        setGender(opt?.value);
                                                        setFieldValue("hosts_gender", opt?.value);
                                                    }}
                                                    blurInputOnSelect={true}
                                                    placeholder=""
                                                    isSearchable={false}
                                                    isClearable={false}
                                                    styles={customStyles}
                                                    options={genderOptions}
                                                    error={errors.gender}
                                                    touched={touched.gender}
                                                />

                                            </div>

                                            <div className="input-field col s4">
                                                <Select
                                                    name="hosts_status"
                                                    id="hosts_status"
                                                    onBlur={() => setFieldTouched("hosts_status", true)}
                                                    value={statusOptions.filter(opt => { return (opt.value === status) })}
                                                    onChange={(opt, e) => {
                                                        setStatus(opt?.value);
                                                        setFieldValue("hosts_status", opt?.value);
                                                    }}
                                                    blurInputOnSelect={true}
                                                    placeholder=""
                                                    isSearchable={false}
                                                    isClearable={false}
                                                    styles={customStyles}
                                                    options={statusOptions}
                                                    error={errors.status}
                                                    touched={touched.status}
                                                />

                                            </div>

                                            <div className="input-field col s4">
                                                <Select
                                                    name="hosts_lang"
                                                    id="hosts_lang"
                                                    onBlur={() => setFieldTouched("hosts_lang", true)}
                                                    value={langsOptions.filter(opt => { return (opt.value === lang) })}
                                                    onChange={(opt, e) => {
                                                        setLang(opt?.value);
                                                        setFieldValue("hosts_lang", opt?.value);
                                                    }}
                                                    blurInputOnSelect={true}
                                                    placeholder=""
                                                    isSearchable={false}
                                                    isClearable={false}
                                                    styles={customStyles}
                                                    options={langsOptions}
                                                    error={errors.lang}
                                                    touched={touched.lang}
                                                />

                                            </div>

                                            <div className="input-field col s6">
                                                <Field autoComplete="off" id="hosts_first_name" name="hosts_first_name" type="text" className={errors.hosts_first_name ? "error validate" : "validate"} />
                                                <label htmlFor="hosts_first_name" className={first_name !== '' ? 'active' : ''}>{'First Name'}</label>
                                                {errors.hosts_first_name && touched.hosts_first_name ? (
                                                    <small className="errorTxt2"><div id="hosts_first_name-error" className="error">{errors.hosts_first_name}</div></small>
                                                ) : null}
                                            </div>
                                            <div className="input-field col s6">
                                                <Field autoComplete="off" id="hosts_last_name" name="hosts_last_name" type="text" className={errors.hosts_last_name ? "error validate" : "validate"} />
                                                <label htmlFor="hosts_last_name" className={last_name !== '' ? 'active' : ''}>{'Last Name'}</label>
                                                {errors.hosts_last_name && touched.hosts_last_name ? (
                                                    <small className="errorTxt2"><div id="hosts_last_name-error" className="error">{errors.hosts_last_name}</div></small>
                                                ) : null}
                                            </div>
                                            <div className="input-field col s6">
                                                <Field autoComplete="off" id="hosts_email" name="hosts_email" type="email" className={errors.hosts_email ? "error validate" : "validate"} />
                                                <label htmlFor="hosts_email" className={email !== '' ? 'active' : ''}>{'Email'}</label>
                                                {errors.hosts_email && touched.hosts_email ? (
                                                    <small className="errorTxt2"><div id="email-error" className="error">{errors.hosts_email}</div></small>
                                                ) : null}
                                            </div>
                                            <div className="input-field col s6">
                                                <Field autoComplete="off" id="hosts_identification" name="hosts_identification" type="text" className={errors.hosts_identification ? "error validate" : "validate"} />
                                                <label htmlFor="hosts_identification" className={identification !== '' ? 'active' : ''}>{'Passeport / CIN'}</label>
                                                {errors.hosts_identification && touched.hosts_identification ? (
                                                    <small className="errorTxt2"><div id="hosts_identification-error" className="error">{errors.hosts_identification}</div></small>
                                                ) : null}
                                            </div>
                                            <div className="input-field col s6">
                                                <Field autoComplete="off" id="hosts_phone" name="hosts_phone" type="text" className={errors.hosts_phone ? "error validate" : "validate"} />
                                                <label htmlFor="hosts_phone" className={phone !== '' ? 'active' : ''}>{'Téléphone'}</label>
                                                {errors.hosts_phone && touched.hosts_phone ? (
                                                    <small className="errorTxt2"><div id="hosts_phone-error" className="error">{errors.hosts_phone}</div></small>
                                                ) : null}
                                            </div>
                                            <div className="input-field col s6">
                                                <Field autoComplete="off" id="hosts_country" name="hosts_country" type="text" className={errors.hosts_country ? "error validate" : "validate"} />
                                                <label htmlFor="hosts_country" className={country !== '' ? 'active' : ''}>{'Country'}</label>
                                                {errors.hosts_country && touched.hosts_country ? (
                                                    <small className="errorTxt2"><div id="hosts_country-error" className="error">{errors.hosts_country}</div></small>
                                                ) : null}
                                            </div>
                                            <div className="input-field col s6">
                                                <Field autoComplete="off" id="hosts_city" name="hosts_city" type="text" className={errors.hosts_city ? "error validate" : "validate"} />
                                                <label htmlFor="hosts_city" className={city !== '' ? 'active' : ''}>{'City'}</label>
                                                {errors.hosts_city && touched.hosts_city ? (
                                                    <small className="errorTxt2"><div id="hosts_city-error" className="error">{errors.hosts_city}</div></small>
                                                ) : null}
                                            </div>
                                            <div className="input-field col s6">
                                                <Field autoComplete="off" id="hosts_zip" name="hosts_zip" type="text" className={errors.hosts_zip ? "error validate" : "validate"} />
                                                <label htmlFor="hosts_zip" className={zip !== '' ? 'active' : ''}>{'Zip Code'}</label>
                                                {errors.hosts_zip && touched.hosts_zip ? (
                                                    <small className="errorTxt2"><div id="hosts_zip-error" className="error">{errors.hosts_zip}</div></small>
                                                ) : null}
                                            </div>
                                            <div className="input-field col s12">
                                                <Field autoComplete="off" id="hosts_adresse" name="hosts_adresse" type="text" className={errors.hosts_adresse ? "error validate" : "validate"} />
                                                <label htmlFor="hosts_adresse" className={adresse !== '' ? 'active' : ''}>{'Adresse'}</label>
                                                {errors.hosts_adresse && touched.hosts_adresse ? (
                                                    <small className="errorTxt2"><div id="hosts_adresse-error" className="error">{errors.hosts_adresse}</div></small>
                                                ) : null}
                                            </div>
                                            <div className="input-field col s12">
                                                <Field autoComplete="off" id="hosts_password" name="hosts_password" type="password" className={errors.hosts_password ? "error validate" : "validate"} />
                                                <label htmlFor="hosts_password" className={password !== '' ? 'active' : ''}>{'Password'}</label>
                                                {errors.hosts_password && touched.hosts_password ? (
                                                    <small className="errorTxt2"><div id="hosts_password-error" className="error">{errors.hosts_password}</div></small>
                                                ) : null}
                                            </div>

                                        </div>

                                        <div className="card-action pl-0 pr-0 right-align">

                                            <button type="submit" className="btn-large pr-10 pl-10  add-contact" disabled={isSubmitting}>
                                                <span>{'Save'}</span>
                                            </button>

                                        </div>

                                    </Form>
                                )}

                            </Formik>
                        </div>
                        <div className='col s12' id="Extras">
                            {
                                id &&
                                <Formik
                                    initialValues={{
                                        id: id ? id : '',
                                        hosts_property_id: property_id ? property_id : '',
                                        hosts_property_name: property_name ? property_name : '',
                                        hosts_base_price: basePrice ? basePrice : '',
                                        hosts_moyenne_price: moyennePrice ? moyennePrice : '',
                                        hosts_haute_price: hautePrice ? hautePrice : '',
                                        hosts_special_price: specialPrice ? specialPrice : '',
                                        hosts_min_nights: minNights ? minNights : '',
                                        hosts_ical: hosts_ical ? hosts_ical : '',
                                    }}
                                    enableReinitialize={true}
                                    onSubmit={async (values, { resetForm }) => {
                                        handleExtrasSubmit(values);
                                        // resetForm({ values: '' });
                                    }}
                                >
                                    {({ values, errors, touched, isSubmitting, setFieldValue, setFieldTouched }) => (
                                        <Form className="edit-contact-item mb-5">
                                            <div className="row">

                                                <div className="input-field col s6">
                                                    <Field autoComplete="off" id="hosts_property_id" name="hosts_property_id" type="text" className={errors.hosts_property_id ? "error validate" : "validate"} />
                                                    <label htmlFor="hosts_property_id" className={property_id !== '' ? 'active' : ''}>{'Property ID'}</label>
                                                    {errors.hosts_property_id && touched.hosts_property_id ? (
                                                        <small className="errorTxt2"><div id="hosts_property_id-error" className="error">{errors.hosts_property_id}</div></small>
                                                    ) : null}
                                                </div>

                                                <div className="input-field col s6">
                                                    <Field autoComplete="off" id="hosts_property_name" name="hosts_property_name" type="text" className={errors.hosts_property_name ? "error validate" : "validate"} />
                                                    <label htmlFor="hosts_property_name" className={property_name !== '' ? 'active' : ''}>{'Property Name'}</label>
                                                    {errors.hosts_property_name && touched.hosts_property_name ? (
                                                        <small className="errorTxt2"><div id="hosts_property_name-error" className="error">{errors.hosts_property_name}</div></small>
                                                    ) : null}
                                                </div>

                                                <div className="input-field col s6">
                                                    <Field autoComplete="off" id="hosts_base_price" name="hosts_base_price" type="text" className={errors.hosts_base_price ? "error validate" : "validate"} />
                                                    <label htmlFor="hosts_base_price" className={basePrice !== '' ? 'active' : ''}>{'Base Price'}</label>
                                                    {errors.hosts_base_price && touched.hosts_base_price ? (
                                                        <small className="errorTxt2"><div id="hosts_base_price-error" className="error">{errors.hosts_base_price}</div></small>
                                                    ) : null}
                                                </div>

                                                <div className="input-field col s6">
                                                    <Field autoComplete="off" id="hosts_moyenne_price" name="hosts_moyenne_price" type="text" className={errors.hosts_moyenne_price ? "error validate" : "validate"} />
                                                    <label htmlFor="hosts_moyenne_price" className={moyennePrice !== '' ? 'active' : ''}>{'Moyenne Price'}</label>
                                                    {errors.hosts_moyenne_price && touched.hosts_moyenne_price ? (
                                                        <small className="errorTxt2"><div id="hosts_moyenne_price-error" className="error">{errors.hosts_moyenne_price}</div></small>
                                                    ) : null}
                                                </div>

                                                <div className="input-field col s6">
                                                    <Field autoComplete="off" id="hosts_haute_price" name="hosts_haute_price" type="text" className={errors.hosts_haute_price ? "error validate" : "validate"} />
                                                    <label htmlFor="hosts_haute_price" className={hautePrice !== '' ? 'active' : ''}>{'Haute Price'}</label>
                                                    {errors.hosts_haute_price && touched.hosts_haute_price ? (
                                                        <small className="errorTxt2"><div id="hosts_haute_price-error" className="error">{errors.hosts_haute_price}</div></small>
                                                    ) : null}
                                                </div>

                                                <div className="input-field col s6">
                                                    <Field autoComplete="off" id="hosts_special_price" name="hosts_special_price" type="text" className={errors.hosts_special_price ? "error validate" : "validate"} />
                                                    <label htmlFor="hosts_special_price" className={specialPrice !== '' ? 'active' : ''}>{'Special Price'}</label>
                                                    {errors.hosts_special_price && touched.hosts_special_price ? (
                                                        <small className="errorTxt2"><div id="hosts_special_price-error" className="error">{errors.hosts_special_price}</div></small>
                                                    ) : null}
                                                </div>

                                                <div className="input-field col s6">
                                                    <Field autoComplete="off" id="hosts_min_nights" name="hosts_min_nights" type="text" className={errors.hosts_min_nights ? "error validate" : "validate"} />
                                                    <label htmlFor="hosts_min_nights" className={minNights !== '' ? 'active' : ''}>{'Min Nights'}</label>
                                                    {errors.hosts_min_nights && touched.hosts_min_nights ? (
                                                        <small className="errorTxt2"><div id="hosts_min_nights-error" className="error">{errors.hosts_min_nights}</div></small>
                                                    ) : null}
                                                </div>

                                                <div className="input-field col s6">
                                                    <Field autoComplete="off" id="hosts_ical" name="hosts_ical" type="text" className={errors.hosts_ical ? "error validate" : "validate"} />
                                                    <label htmlFor="hosts_ical" className={hosts_ical !== '' ? 'active' : ''}>{'Ical'}</label>
                                                    {errors.hosts_ical && touched.hosts_ical ? (
                                                        <small className="errorTxt2"><div id="hosts_ical-error" className="error">{errors.hosts_ical}</div></small>
                                                    ) : null}
                                                </div>

                                            </div>

                                            <div className="card-action pl-0 pr-0 right-align">

                                                <button type="submit" className="btn-large pr-10 pl-10  add-contact" disabled={isSubmitting}>
                                                    <span>{'Save'}</span>
                                                </button>

                                            </div>

                                        </Form>
                                    )}

                                </Formik>
                            }
                        </div>
                        <div className="col s12" id="versements">
                            {
                                id &&
                                <Formik
                                    initialValues={{
                                        id_host: id ? id : '',
                                        host_versement_reservation_id: null,
                                        host_versement_date: '',
                                        host_versement_amount: '',
                                        host_versement_guest_name: '',
                                        host_versement_guest_count: '',
                                        host_versement_reservation_date: '',
                                        mode_versement_id: '',
                                    }}
                                    enableReinitialize={true}
                                    onSubmit={async (values, { resetForm, setFieldValue }) => {
                                        handleVersementSubmit(values);
                                        resetForm({ values: '' });
                                        setFieldValue("mode_versement_id", null);
                                    }}
                                >
                                    {({ values, errors, touched, isSubmitting, setFieldValue, setFieldTouched }) => (
                                        <Form className="edit-contact-item mb-5">
                                            <div className="row">

                                                <div className="input-field col s6">
                                                    <Field autoComplete="off" id="host_versement_reservation_id" name="host_versement_reservation_id" type="text" className={errors.host_versement_reservation_id ? "error validate" : "validate"} />
                                                    <label htmlFor="host_versement_reservation_id" className="active">{'Reservation ID'}</label>
                                                    {errors.host_versement_reservation_id && touched.host_versement_reservation_id ? (
                                                        <small className="errorTxt2"><div id="host_versement_reservation_id-error" className="error">{errors.host_versement_reservation_id}</div></small>
                                                    ) : null}
                                                </div>

                                                <div className="input-field col s6">
                                                    <Field autoComplete="off" id="host_versement_date" name="host_versement_date" type="text" className={errors.host_versement_date ? "error validate" : "validate"} />
                                                    <label htmlFor="host_versement_date" className="active">{'Date de versement'}</label>
                                                    {errors.host_versement_date && touched.host_versement_date ? (
                                                        <small className="errorTxt2"><div id="host_versement_date-error" className="error">{errors.host_versement_date}</div></small>
                                                    ) : null}
                                                </div>

                                                <div className="input-field col s6">
                                                    <Field autoComplete="off" id="host_versement_amount" name="host_versement_amount" type="text" className={errors.host_versement_amount ? "error validate" : "validate"} />
                                                    <label htmlFor="host_versement_amount" className="active">{'Montant'}</label>
                                                    {errors.host_versement_amount && touched.host_versement_amount ? (
                                                        <small className="errorTxt2"><div id="host_versement_amount-error" className="error">{errors.host_versement_amount}</div></small>
                                                    ) : null}
                                                </div>

                                                <div className="input-field col s6">
                                                    <Field autoComplete="off" id="host_versement_guest_name" name="host_versement_guest_name" type="text" className={errors.host_versement_guest_name ? "error validate" : "validate"} />
                                                    <label htmlFor="host_versement_guest_name" className="active">{'Nom client'}</label>
                                                    {errors.host_versement_guest_name && touched.host_versement_guest_name ? (
                                                        <small className="errorTxt2"><div id="host_versement_guest_name-error" className="error">{errors.host_versement_guest_name}</div></small>
                                                    ) : null}
                                                </div>

                                                <div className="input-field col s6">
                                                    <Field autoComplete="off" id="host_versement_guest_count" name="host_versement_guest_count" type="text" className={errors.host_versement_guest_count ? "error validate" : "validate"} />
                                                    <label htmlFor="host_versement_guest_count" className="active">{'Nombre de visiteurs'}</label>
                                                    {errors.host_versement_guest_count && touched.host_versement_guest_count ? (
                                                        <small className="errorTxt2"><div id="host_versement_guest_count-error" className="error">{errors.host_versement_guest_count}</div></small>
                                                    ) : null}
                                                </div>

                                                <div className="input-field col s6">
                                                    <Field autoComplete="off" id="host_versement_reservation_date" name="host_versement_reservation_date" type="text" className={errors.host_versement_reservation_date ? "error validate" : "validate"} />
                                                    <label htmlFor="host_versement_reservation_date" className="active">{'Reservation date'}</label>
                                                    {errors.host_versement_reservation_date && touched.host_versement_reservation_date ? (
                                                        <small className="errorTxt2"><div id="host_versement_reservation_date-error" className="error">{errors.host_versement_reservation_date}</div></small>
                                                    ) : null}
                                                </div>

                                                <div className="input-field col s6">
                                                    <label htmlFor="host_versement_reservation_date" className="active">{'IBAN'}</label>
                                                    {
                                                        modeVersementOptions && modeVersementOptions.length > 0 &&
                                                        <Select
                                                            name="mode_versement_id"
                                                            id="mode_versement_id"
                                                            onBlur={() => setFieldTouched("mode_versement_id", true)}
                                                            onChange={(opt, e) => {
                                                                setGender(opt?.value);
                                                                setFieldValue("mode_versement_id", opt?.value);
                                                            }}
                                                            blurInputOnSelect={true}
                                                            placeholder=""
                                                            isSearchable={false}
                                                            isClearable={false}
                                                            styles={customStyles}
                                                            options={modeVersementOptions}
                                                            error={errors.gender}
                                                            touched={touched.gender}
                                                        />

                                                    }


                                                </div>

                                            </div>

                                            <div className="card-action pl-0 pr-0 right-align">

                                                <button type="submit" className="btn-large pr-10 pl-10  add-contact" disabled={isSubmitting}>
                                                    <span>{'Save'}</span>
                                                </button>

                                            </div>

                                        </Form>
                                    )}

                                </Formik>
                            }

                            {
                                hostSelected?.host?.hosts_versement?.map((item, index) => {
                                    return (
                                        <div className="card card-content" key={"hosts_versement-" + index} style={{ background: 'rgb(54 62 63)' }}>
                                            <button type="button" className="repeteur-item-close btn" onClick={() => HandlerVersementDelete(item.id)}>
                                                <i className="material-icons">close</i>
                                            </button>
                                            <table>
                                                <tbody>
                                                    <tr>
                                                        <td>Reservation ID:</td>
                                                        <td>{item.host_versement_reservation_id}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Date de versement</td>
                                                        <td>{item.host_versement_date}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Montant:</td>
                                                        <td>{item.host_versement_amount}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Nom client:</td>
                                                        <td>{item.host_versement_guest_name}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Nombre de visiteurs:</td>
                                                        <td>{item.host_versement_guest_count}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Reservation date:</td>
                                                        <td>{item.host_versement_reservation_date}</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    )
                                }
                                )
                            }

                        </div>
                        <div className="col s12" id="mode_versement">

                            <div className="card card-content" style={{ background: 'rgb(54 62 63)' }}>
                                {
                                    hostSelected?.host?.hosts_mode_versement?.map((item, index) => {
                                        return (
                                            <table key={"hosts_mode_versement-" + index}>
                                                <tbody>
                                                    <tr>
                                                        <td>Type de compte bancaire:</td>
                                                        <td>
                                                            {
                                                                item.versement_type === 1 ? 'Compte Personnel' :
                                                                    item.versement_type === 2 ? 'Joint' :
                                                                        item.versement_type === 3 ? 'Professionnel' : ''
                                                            }
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>Nom:</td>
                                                        <td>{item.versement_first_name}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Prenom:</td>
                                                        <td>{item.versement_last_name}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Role:</td>
                                                        <td>
                                                            {
                                                                item.versement_role === 1 ? 'Propritaire du logement' :
                                                                    item.versement_role === 2 ? 'Gestionnaire du logement' : ''
                                                            }
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>Nom de la banque:</td>
                                                        <td>{item.versement_bank}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Code SWIFT/BIC:</td>
                                                        <td>{item.versement_swift}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>IBAN:</td>
                                                        <td>{item.versement_iban}</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        )
                                    })
                                }
                            </div>

                        </div>
                        <div className="col s12" id="icals">
                            {
                                hostSelected?.host?.hosts_icals?.map((item, index) => {
                                    return (
                                        <div className="card card-content" key={"hosts_icals-" + index} style={{ background: 'rgb(54 62 63)' }}>
                                            <table>
                                                <tbody>
                                                    <tr>
                                                        <td>Name:</td>
                                                        <td>{item.ical_name}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>URL:</td>
                                                        <td>{item.ical_url}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>status:</td>
                                                        <td>
                                                            {
                                                                item.ical_status == 0 ? 'Inactive' :
                                                                    item.ical_status == 1 ? 'En attente' :
                                                                        item.ical_status == 2 ? 'Active' : ''
                                                            }
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    )
                                }
                                )
                            }
                        </div>
                    </div>

                    {/* sidebar tabs */}

                </div>
            </div>



        </div>
    </React.Fragment>;
}