import React, { useEffect } from 'react';
import DataTable from 'react-data-table-component';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { pathListner } from '../../actions/pathActions';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { listReservations, saveReservation, deleteReservation, selectedReservation, toggleReservationsSidebar } from '../../actions/reservationsActions';
import ReservationsFormSidebar from './ReservationsFormSidebar';
import ReservationsFiltre from './ReservationsFiltre';
import ReservationsDataTable from './ReservationsDatatable';
import { listPermissions } from '../../actions/PermissionsActions';
import useHasPermission from '../../hooks/useHasPermission';

export default function Reservations() {

    const dispatch = useDispatch();
    const canCreate = useHasPermission("/Reservations", 'create');
    useEffect(() => {

        dispatch(listReservations());
        dispatch(listPermissions());
        dispatch(pathListner('/reservations'));

    }, [])

    const addReservation = () => {
        dispatch(selectedReservation(null));
        dispatch(toggleReservationsSidebar(true));
    }

    return <React.Fragment>

        <ToastContainer
            position="top-center"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
        />

        <div className="row">
            <div className="content-wrapper-before gradient-45deg-indigo-purple"></div>
            <div className="col s12">
                <div className="container">
                    {/* <!-- Add new lead popup --> */}
                    <div className="lead-overlay"></div>
                    {
                        /*
                        canCreate ? (
                            <div style={{ bottom: '54px', right: '19px' }} className="fixed-action-btn direction-top">
                                <a className="btn-floating btn-large primary-text gradient-shadow lead-sidebar-trigger" onClick={addReservation}>
                                    <i className="material-icons">add_box</i>
                                </a>
                            </div>
                        ) : undefined
                        */
                    }
                    {/* <!-- Add new lead popup Ends--> */}

                    <ReservationsFiltre />

                    <ReservationsDataTable />

                    <ReservationsFormSidebar />

                </div>
                <div className="content-overlay"></div>
            </div>
        </div>
    </React.Fragment>;
}
