const { HOSTS_LIST_REQUEST,
    HOSTS_LIST_SUCCESS,
    HOSTS_LIST_FAIL,
    HOSTS_SAVE_REQUEST,
    HOSTS_SAVE_SUCCESS,
    HOSTS_SAVE_FAIL,
    HOSTS_DELETE_REQUEST,
    HOSTS_DELETE_SUCCESS,
    HOSTS_DELETE_FAIL,
    SELECTED_HOST,
    HOSTS_SIDEBAR_IS_TOGGLED,
    HOST_AUTH_PERMISSIONS_REQUEST,
    HOST_AUTH_PERMISSIONS_SUCCESS,
    HOST_AUTH_PERMISSIONS_FAIL,
    HOSTS_SAVE_EXTRAS_REQUEST,
    HOSTS_SAVE_EXTRAS_SUCCESS,
    HOSTS_SAVE_EXTRAS_FAIL,
    HOSTS_SAVE_VERSEMENT_REQUEST,
    HOSTS_SAVE_VERSEMENT_SUCCESS,
    HOSTS_SAVE_VERSEMENT_FAIL,
    HOSTS_DELETE_VERSEMENT_REQUEST,
    HOSTS_DELETE_VERSEMENT_SUCCESS,
    HOSTS_DELETE_VERSEMENT_FAIL

} = require("../constants/hostsConstants");

function hostsListReducer(state = { loading: true, hosts: [] }, action) {

    switch (action.type) {
        case HOSTS_LIST_REQUEST:
            return { loading: true };
        case HOSTS_LIST_SUCCESS:
            return { loading: false, hosts: action.payload };
        case HOSTS_LIST_FAIL:
            return { loading: false, error: action.payload };
        default:
            return state;
    }

}

function hostAuthPermissionsReducer(state = { loading: true, hostAuthPermissions: [] }, action) {

    switch (action.type) {
        case HOST_AUTH_PERMISSIONS_REQUEST:
            return { loading: true };
        case HOST_AUTH_PERMISSIONS_SUCCESS:
            return { loading: false, hostAuthPermissions: action.payload };
        case HOST_AUTH_PERMISSIONS_FAIL:
            return { loading: false, error: action.payload };
        default:
            return state;
    }

}

function hostsSaveReducer(state = { hosts: [] }, action) {

    switch (action.type) {
        case HOSTS_SAVE_REQUEST:
            return { loading: true };
        case HOSTS_SAVE_SUCCESS:
            return { loading: false, success: true, hosts: action.payload };
        case HOSTS_SAVE_FAIL:
            return { loading: false, error: action.payload };
        default:
            return state;
    }

}

function hostsDeleteReducer(state = { hosts: [] }, action) {

    switch (action.type) {
        case HOSTS_DELETE_REQUEST:
            return { loading: true };
        case HOSTS_DELETE_SUCCESS:
            return { loading: false, success: true, hosts: action.payload };
        case HOSTS_DELETE_FAIL:
            return { loading: false, error: action.payload };
        default:
            return state;
    }

}

function selectedHostReducer(state = { host: null }, action) {

    switch (action.type) {
        case SELECTED_HOST:
            return { host: action.payload };
        default:
            return state;
    }

}

function toggleHostsSidebarReducer(state = { toggle: false }, action) {

    switch (action.type) {
        case HOSTS_SIDEBAR_IS_TOGGLED:
            return { toggle: action.payload };
        default:
            return state;
    }

}

function hostsSaveExtrasReducer(state = { hosts: [] }, action) {

    switch (action.type) {
        case HOSTS_SAVE_EXTRAS_REQUEST:
            return { loading: true };
        case HOSTS_SAVE_EXTRAS_SUCCESS:
            return { loading: false, success: true, hosts: action.payload };
        case HOSTS_SAVE_EXTRAS_FAIL:
            return { loading: false, error: action.payload };
        default:
            return state;
    }

}

function hostsSaveVersementReducer(state = { hosts: [] }, action) {

    switch (action.type) {
        case HOSTS_SAVE_VERSEMENT_REQUEST:
            return { loading: true };
        case HOSTS_SAVE_VERSEMENT_SUCCESS:
            return { loading: false, success: true, hosts: action.payload };
        case HOSTS_SAVE_VERSEMENT_FAIL:
            return { loading: false, error: action.payload };
        default:
            return state;
    }

}

function hostsDeleteVersementReducer(state = { hosts: [] }, action) {

    switch (action.type) {
        case HOSTS_DELETE_VERSEMENT_REQUEST:
            return { loading: true };
        case HOSTS_DELETE_VERSEMENT_SUCCESS:
            return { loading: false, success: true, hosts: action.payload };
        case HOSTS_DELETE_VERSEMENT_FAIL:
            return { loading: false, error: action.payload };
        default:
            return state;
    }

}

export {
    hostsListReducer,
    hostsSaveReducer,
    hostsDeleteReducer,
    selectedHostReducer,
    toggleHostsSidebarReducer,
    hostAuthPermissionsReducer,
    hostsSaveExtrasReducer,
    hostsSaveVersementReducer,
    hostsDeleteVersementReducer
}