import React, { useEffect } from 'react'
import { Switch, Route, BrowserRouter } from 'react-router-dom';
import { useSelector } from 'react-redux'
import { useDispatch } from 'react-redux';

// pages 
import Users from '../components/Users/index';
import Profile from '../components/Users/Profile/Profile';
import Hosts from '../components/Hosts/index';
import Permissions from '../components/Permissions/index';
import Roles from '../components/Roles/index';
import FilesManager from '../components/FilesManager/index';
import Forbidden from '../components/errors/Forbidden'
import NotFound from '../components/errors/notFound'
import Header from '../components/Layout/Header'
import SideNav from '../components/Layout/SideNav'
import Footer from '../components/Layout/Footer'
import Reservations from '../components/Reservations';

// AuthorizedApps
import AuthorizedApps from '../components/authorizedApps/AuthorizedApps'
// Loader 
import Loading from '../components/loader/Loading'

export default function Routes() {

    const dispatch = useDispatch();

    const { loading, userAuthPermissions } = useSelector(state => state.userAuthPermissions);
    const { sideBarItems } = useSelector(state => state.sideBarItems);

    const hasPermissions = (path) => {
        const currentItem = sideBarItems?.find((item) => {
            return item.to === path
        })
        return userAuthPermissions?.find((item) => {
            return item?.key === currentItem?.key || item?.key === "ALL" && item.show
        })
    }

    /* routes */

    return (
        <React.Fragment>
            <Header />
            <SideNav />
            <div id="main" >
                <Switch>
                    {/* hosts  */}
                    <Route path="/" exact={true} render={props => {
                        const path = "/";
                        if (loading) {
                            return <Loading {...props} />
                        }
                        if (hasPermissions(path)) {
                            return <Hosts {...props} />
                        } else {
                            return <Forbidden {...props} />
                        }

                    }} />

                    <Route path="/hosts" exact={true} render={props => {
                        const path = "/hosts";
                        if (loading) {
                            return <Loading {...props} />
                        }
                        if (hasPermissions(path)) {
                            return <Hosts {...props} />
                        } else {
                            return <Forbidden {...props} />
                        }

                    }} />

                    {/* users  */}
                    <Route path="/users" exact={true} render={props => {
                        const path = "/users";
                        if (loading) {
                            return <Loading {...props} />
                        }
                        if (hasPermissions(path)) {
                            return <Users {...props} />
                        } else {
                            return <Forbidden {...props} />
                        }

                    }} />
                    {/* permissions  */}
                    <Route path="/permissions" exact={true} render={props => {
                        const path = "/permissions";
                        if (loading) {
                            return <Loading {...props} />
                        }
                        if (hasPermissions(path)) {
                            return <Permissions {...props} />
                        } else {
                            return <Forbidden {...props} />
                        }

                    }} />
                    {/* roles */}
                    <Route path="/roles" exact={true} render={props => {
                        if (loading) {
                            return <Loading {...props} />
                        }
                        const path = "/roles";
                        if (hasPermissions(path)) {
                            return <Roles {...props} />
                        } else {
                            return <Forbidden {...props} />
                        }

                    }} />
                    {/* profile */}
                    <Route path="/profile" exact={true} render={props => {
                        if (loading) {
                            return <Loading {...props} />
                        }
                        const path = "/profile";
                        if (hasPermissions(path)) {
                            return <Profile {...props} />
                        } else {
                            return <Forbidden {...props} />
                        }

                    }} />
                    {/* files  */}
                    <Route path="/files-manager/:filtre?" exact={true} render={props => {
                        if (loading) {
                            return <Loading {...props} />
                        }
                        const path = "/files-manager";

                        if (hasPermissions(path)) {
                            return <FilesManager {...props} />
                        } else {
                            return <Forbidden {...props} />
                        }

                    }} />

                    {/* Authorized   */}
                    <Route path="/authorized-apps/:trush?" exact={true} render={props => {
                        if (loading) {
                            return <Loading {...props} />
                        }
                        const path = "/authorized-apps";
                        if (hasPermissions(path)) {
                            return <AuthorizedApps {...props} />
                        } else {
                            return <Forbidden {...props} />
                        }

                    }} />

                    {/* Reservations  */}
                    <Route path="/reservations" exact={true} render={props => {
                        const path = "/reservations";
                        if (loading) {
                            return <Loading {...props} />
                        }
                        if (hasPermissions(path)) {
                            return <Reservations {...props} />
                        } else {
                            return <Forbidden {...props} />
                        }

                    }} />

                    {/* 404 */}
                    <Route component={NotFound} />
                </Switch>
            </div>
            <Footer />

        </React.Fragment>

    )
}
