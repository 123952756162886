import axios from 'axios';
import { headers } from '../config/ApiHeaders'
import { apiProxy } from '../config/Settings'

const {
    HOSTS_LIST_REQUEST,
    HOSTS_LIST_SUCCESS,
    HOSTS_LIST_FAIL,
    HOSTS_SAVE_REQUEST,
    HOSTS_SAVE_SUCCESS,
    HOSTS_SAVE_FAIL,
    HOSTS_DELETE_REQUEST,
    HOSTS_DELETE_SUCCESS,
    HOSTS_DELETE_FAIL,
    SELECTED_HOST,
    HOSTS_SIDEBAR_IS_TOGGLED,
    HOSTS_SAVE_EXTRAS_REQUEST,
    HOSTS_SAVE_EXTRAS_SUCCESS,
    HOSTS_SAVE_EXTRAS_FAIL
} = require("../constants/hostsConstants");



const listHosts = () => async (dispatch) => {
    try {
        dispatch({ type: HOSTS_LIST_REQUEST });
        const { data } = await axios({
            method: 'get',
            url: apiProxy + '/api/hosts',
            headers: headers
        });
        dispatch({ type: HOSTS_LIST_SUCCESS, payload: data });
    } catch (error) {
        dispatch({ type: HOSTS_LIST_FAIL, payload: error.message });
    }

}

const saveHost = (host, callback) => async (dispatch) => {


    if (!host.id) {
        dispatch({ type: HOSTS_SAVE_REQUEST, payload: { host } });
        axios({
            method: 'post',
            url: apiProxy + '/api/hosts',
            headers: headers,
            data: host
        }).then(data => {
            callback({
                error: false,
                data: data,
                message: null
            });
            dispatch({ type: HOSTS_SAVE_SUCCESS, payload: data });
        }).catch(err => {
            callback({
                error: true,
                data: null,
                message: err.response.data.message
            });
            dispatch({ type: HOSTS_SAVE_FAIL, payload: err.response.data.message });
        });

    } else {
        dispatch({ type: HOSTS_SAVE_REQUEST, payload: { host } });

        axios({
            method: 'patch',
            url: apiProxy + "/api/hosts/" + host.id,
            headers: headers,
            data: host
        }).then(data => {
            callback({
                error: false,
                data: data,
                message: null
            });
            dispatch({ type: HOSTS_SAVE_SUCCESS, payload: data });
        }).catch(err => {
            callback({
                error: true,
                data: null,
                message: err.response.data.message
            });
            dispatch({ type: HOSTS_SAVE_FAIL, payload: err.response.data.message });
        });

    }

}

const deleteHost = (host, callback) => async (dispatch) => {
    try {
        dispatch({ type: HOSTS_DELETE_REQUEST });
        const { data } = await axios(
            {
                method: 'delete',
                url: apiProxy + "/api/hosts/" + host.id,
                headers: headers
            });
        callback(data);
        dispatch({ type: HOSTS_DELETE_SUCCESS, payload: data });
    } catch (error) {
        dispatch({ type: HOSTS_DELETE_FAIL, payload: error.message });
    }

}

const selectedHost = (host) => async (dispatch) => {
    dispatch({ type: SELECTED_HOST, payload: host });
}

const toggleHostsSidebar = (toggle) => async (dispatch) => {
    dispatch({ type: HOSTS_SIDEBAR_IS_TOGGLED, payload: toggle });
}

const saveHostExtras = (host, callback) => async (dispatch) => {

    dispatch({ type: HOSTS_SAVE_EXTRAS_REQUEST, payload: { host } });

    axios({
        method: 'post',
        url: apiProxy + "/api/hosts/update-extras/" + host.id,
        headers: headers,
        data: host
    }).then(data => {
        callback({
            error: false,
            data: data,
            message: null
        });
        dispatch({ type: HOSTS_SAVE_EXTRAS_SUCCESS, payload: data });
    }).catch(err => {
        callback({
            error: true,
            data: null,
            message: err.response.data.message
        });
        dispatch({ type: HOSTS_SAVE_EXTRAS_FAIL, payload: err.response.data.message });
    });

}

const saveHostVersements = (host, callback) => async (dispatch) => {

    dispatch({ type: HOSTS_SAVE_EXTRAS_REQUEST, payload: { host } });

    axios({
        method: 'post',
        url: apiProxy + "/api/hosts/save-versement/",
        headers: headers,
        data: host
    }).then(data => {
        callback({
            error: false,
            data: data,
            message: null
        });
        dispatch({ type: HOSTS_SAVE_EXTRAS_SUCCESS, payload: data });
    }).catch(err => {
        callback({
            error: true,
            data: null,
            message: err.response.data.message
        });
        dispatch({ type: HOSTS_SAVE_EXTRAS_FAIL, payload: err.response.data.message });
    });

}

const deleteHostVersement = (versement_id, callback) => async (dispatch) => {

    dispatch({ type: HOSTS_SAVE_EXTRAS_REQUEST, payload: { versement_id } });

    axios({
        method: 'post',
        url: apiProxy + "/api/hosts/delete-versement/",
        headers: headers,
        data: { "versement_id": versement_id }
    }).then(data => {
        callback({
            error: false,
            data: data,
            message: null
        });
        dispatch({ type: HOSTS_SAVE_EXTRAS_SUCCESS, payload: data });
    }).catch(err => {
        callback({
            error: true,
            data: null,
            message: err.response.data.message
        });
        dispatch({ type: HOSTS_SAVE_EXTRAS_FAIL, payload: err.response.data.message });
    });

}

export {
    listHosts,
    saveHost,
    deleteHost,
    selectedHost,
    toggleHostsSidebar,
    saveHostExtras,
    saveHostVersements,
    deleteHostVersement
}