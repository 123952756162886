import {
    createStore,
    combineReducers,
    applyMiddleware,
    compose
} from 'redux';
import thunk from 'redux-thunk';
import {
    locationReducer
} from './reducers/locationReducer';
import {
    hostsListReducer,
    hostsSaveReducer,
    hostsDeleteReducer,
    selectedHostReducer,
    toggleHostsSidebarReducer,
    hostsSaveExtrasReducer,
    hostsSaveVersementReducer,
    hostsDeleteVersementReducer
} from './reducers/hostsReducers';
import {
    usersListReducer,
    usersSaveReducer,
    usersDeleteReducer,
    selectedUserReducer,
    toggleUsersSidebarReducer,
    userAuthPermissionsReducer
} from './reducers/usersReducers';
import {
    rolesListReducer,
    rolesSaveReducer,
    rolesDeleteReducer,
    selectedRoleReducer,
    toggleRolesSidebarReducer
} from './reducers/rolesReducer';
import {
    permissionsListReducer,
    permissionsSaveReducer,
    permissionsDeleteReducer,
    selectedPermissionsReducer,
    togglePermissionsSidebarReducer
} from './reducers/permissinsReducer';
import {
    filesListReducer,
    selectedFileReducer,
    selectedMultiFilesReducer,
    toggleFilesManagerModalReducer,
    uploadFilesReducer
} from './reducers/filesManagerReducers';
import {
    authReducer,
    isLoggedInReducer,
    forgotPasswordReducer,
    verifyResetPasswordLinkReducer,
    resetPasswordReducer
} from './reducers/authReducers';
import {
    authorizdAppsListReducer,
    authorizdAppsSaveReducer,
    authorizdAppsDeleteReducer,
    selectedAuthorizedAppReducer,
    toggleAuthorizedAppsSidebarReducer,
    currentAuthorizedAppReducer,
    authorizdAppsRestoreReducer,
    destroyAuthorizedAppReducer
} from './reducers/AuthorizedAppsReducers';
import {
    setSideBarItemsReducer
} from './reducers/SideBarReducer';
import {
    reservationsListReducer,
    reservationsSaveReducer,
    reservationsDeleteReducer,
    selectedReservationReducer,
    toggleReservationsSidebarReducer
} from './reducers/reservationsReducer';

const initialState = {};
const reducer = combineReducers({

    location: locationReducer,

    //users 
    usersList: usersListReducer,
    usersSave: usersSaveReducer,
    usersDelete: usersDeleteReducer,
    selectedUser: selectedUserReducer,
    toggleUsersSidebar: toggleUsersSidebarReducer,
    userAuthPermissions: userAuthPermissionsReducer,

    //Hosts 
    hostsList: hostsListReducer,
    hostsSave: hostsSaveReducer,
    hostsDelete: hostsDeleteReducer,
    selectedHost: selectedHostReducer,
    toggleHostsSidebar: toggleHostsSidebarReducer,
    hostsSaveExtras: hostsSaveExtrasReducer,
    hostsSaveVersement: hostsSaveVersementReducer,
    hostsDeleteVersement: hostsDeleteVersementReducer,


    //roles
    rolesList: rolesListReducer,
    rolesSave: rolesSaveReducer,
    rolesDelete: rolesDeleteReducer,
    selectedRole: selectedRoleReducer,
    toggleRolesSidebar: toggleRolesSidebarReducer,

    //permissions
    permissionsList: permissionsListReducer,
    permissionsSave: permissionsSaveReducer,
    permissionsDelete: permissionsDeleteReducer,
    selectedPermission: selectedPermissionsReducer,
    togglepermissionsSidebar: togglePermissionsSidebarReducer,

    //fileManager
    filesList: filesListReducer,
    selectedFile: selectedFileReducer,
    selectedMultiFiles: selectedMultiFilesReducer,
    toggleFilesManagerModal: toggleFilesManagerModalReducer,
    uploadData: uploadFilesReducer,

    // auth 
    auth: authReducer,
    login: isLoggedInReducer,
    forgotPassword: forgotPasswordReducer,
    verifyResetPasswordLink: verifyResetPasswordLinkReducer,
    resetPassword: resetPasswordReducer,

    //Ahuthorized apps 
    authorizedAppsList: authorizdAppsListReducer,
    authorizedAppsSave: authorizdAppsSaveReducer,
    authorizedAppsDelete: authorizdAppsDeleteReducer,
    selectedAhutorizedApp: selectedAuthorizedAppReducer,
    toggleAhutorizedAppsSidebar: toggleAuthorizedAppsSidebarReducer,
    currenteAhutorizedApp: currentAuthorizedAppReducer,
    restoredeAhutorizedApp: authorizdAppsRestoreReducer,
    destroyedeAhutorizedApp: destroyAuthorizedAppReducer,

    // sidebar menu data 
    sideBarItems: setSideBarItemsReducer,

    //Reservations
    reservationsList: reservationsListReducer,
    reservationsSave: reservationsSaveReducer,
    reservationsDelete: reservationsDeleteReducer,
    selectedReservation: selectedReservationReducer,
    toggleReservationsSidebar: toggleReservationsSidebarReducer,

})


const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(reducer, initialState, composeEnhancer(applyMiddleware(thunk)));

export default store;