import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { pathListner } from '../../actions/pathActions';

import { listHosts, selectedHost, toggleHostsSidebar } from '../../actions/hostsActions';
import HostsFormSidebar from './HostsFormSidebar';
import HostsFiltre from './HostsFiltre';
import HostsDatatable from './HostsDatatable';
import { listRoles } from '../../actions/rolesActions';
import FileManagerModal from '../FilesManager/FileManagerModal';
import useHasPermission from '../../hooks/useHasPermission'
export default function Hosts() {


    const dispatch = useDispatch();
    const canCreate = useHasPermission("/hosts", 'create');
    useEffect(() => {

        dispatch(pathListner('/hosts'));
        dispatch(listRoles());
        dispatch(listHosts());

    }, []);

    const addHost = () => {
        dispatch(selectedHost(null));
        dispatch(toggleHostsSidebar(true));
    }

    return <React.Fragment>

        <ToastContainer
            position="top-center"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
        />

        <FileManagerModal />

        <div className="row">
            <div className="content-wrapper-before gradient-45deg-indigo-purple"></div>
            <div className="col s12">
                <div className="container">

                    {/* <!-- Add new Host popup --> */}
                    <div className="contact-overlay"></div>
                    {
                        canCreate ? (
                            <div style={{ bottom: '54px', right: '19px' }} className="fixed-action-btn direction-top">
                                <a className="btn-floating btn-large primary-text gradient-shadow contact-sidebar-trigger" onClick={addHost}>
                                    <i className="material-icons">person_add</i>
                                </a>
                            </div>
                        ) : undefined
                    }

                    {/* <!-- Add new Host popup Ends--> */}

                    {/* <HostsFiltre /> */}

                    <HostsDatatable />

                    <HostsFormSidebar />

                </div>
                <div className="content-overlay"></div>
            </div>
        </div>

    </React.Fragment>;
}

