import React, { Component } from 'react'
import NavbarList from './NavbarList'
import NotificationsDropdown from './NotificationsDropdown'
import ProfileDropdown from './ProfileDropdown'

export default class Nav extends Component {
    render() {
        return (
            <nav className="navbar-main navbar-color nav-collapsible sideNav-lock navbar-dark gradient-45deg-indigo-purple no-shadow">
                <div className="nav-wrapper">
                    <NavbarList />
                    {/* <!-- translation-button--> */}
                    {/* <TranslationDropdown/> */}
                    {/* <!-- notifications-dropdown--> */}
                    {/* <NotificationsDropdown/> */}
                    {/* <!-- profile-dropdown--> */}
                    <ProfileDropdown />
                </div>
            </nav>
        )
    }
}
