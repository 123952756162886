import React, { useEffect, useMemo, useState } from 'react';
import DataTable from 'react-data-table-component';
import { useSelector, useDispatch } from 'react-redux';
import { listRoles, saveRole, deleteRole, selectedRole, toggleRolesSidebar } from '../../actions/rolesActions';
import Swal from 'sweetalert2';
import useHasPermission from '../../hooks/useHasPermission'


export default function RolesDatatable() {

    const dispatch = useDispatch();

    const [deleteSuccess, setDeleteSuccess] = useState(false);

    const [filtredData, setFiltredData] = useState([]);

    const rolesList = useSelector(state => state.rolesList);
    const { roles,
        loading,
        error } = rolesList;
    const canUpdate = useHasPermission("/roles", "update");
    const canDelete = useHasPermission("/roles", "delete");
    const roleDelete = useSelector(state => state.rolesDelete);
    const { loading: loadingDelete,
        success: successDelete,
        error: errorDelete } = roleDelete;

    const rolesSave = useSelector(state => state.rolesSave);
    const { loading: loadingSave,
        success: successSave,
        error: errorSave } = rolesSave;

    const columns = [
        {
            name: 'Role',
            selector: 'roles_name',
            sortable: true,
        },
        {
            name: 'Permissions',
            selector: 'permissions',
            sortable: false,
            cell: (role) => <p className="" style={{ width: "100%", height: "100%" }} >
                {role && role.permissions && role.permissions[0] ? role.permissions[0].permissions_name + "..." : '-'}
            </p>,
        },
        {
            cell: (role) => canUpdate ? <button className="btn-flat p-0" style={{ width: "100%", height: "100%" }} onClick={(e) => { editRolesHandler(role); }}>
                <i className="material-icons">edit</i>
            </button> : undefined,
            ignoreRowClick: true,
            allowOverflow: true,
            button: true,
            width: '50px'
        },
        {
            cell: (role) => canDelete ? <button className="btn-flat p-0" style={{ width: "100%", height: "100%" }} onClick={(e) => { deleteRolesHandler(role); }}>
                <i className="material-icons">delete</i>
            </button> : undefined,
            ignoreRowClick: true,
            allowOverflow: true,
            button: true,
            width: '50px'
        }
    ];

    useEffect(() => {

        setFiltredData(roles);

        if (deleteSuccess) {
            setDeleteSuccess(false);
            dispatch(listRoles());
        }

    }, [successSave, deleteSuccess, roles]);

    const editRolesHandler = (roleData) => {
        dispatch(selectedRole(roleData));
        dispatch(toggleRolesSidebar(true));
    }

    const deleteRolesHandler = (roleData) => {
        Swal.fire({
            title: 'Are you sure?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            cancelButtonText: 'Cancel',
            confirmButtonText: 'Delete'
        }).then((result) => {
            if (result.isConfirmed) {

                dispatch(deleteRole(roleData, (response) => {
                    setDeleteSuccess(true);
                    Swal.fire(
                        'Delete',
                        'Role successfully deleted',
                        'success'
                    )
                }));

            }
        })
    }

    const dataFiltre = (e) => {
        let textFiltre = e.target.value;
        let data = roles.filter(item => item.roles_name && item.roles_name.toLowerCase().includes(textFiltre.toLowerCase()));
        setFiltredData(data);
    }

    return <React.Fragment>
        {loading || loadingDelete && <div></div>}
        {error && <div>{error}</div>}
        {errorDelete && <div>{errorDelete}</div>}
        <div className="content-area content-right">
            <div className="app-wrapper">
                <div className="datatable-search">
                    <i className="material-icons mr-2 search-icon">search</i>
                    <input type="text" placeholder="Search" className="app-filter" id="global_filter" onChange={dataFiltre} />
                </div>
                <div id="button-trigger" className="card card card-default scrollspy border-radius-6 fixed-width">
                    <div className="p-0 users-table">
                        <DataTable
                            columns={columns}
                            data={filtredData}
                            selectableRows={true}
                            selectableRowsHighlight={true}
                            pagination={true}
                            paginationComponentOptions={{ noRowsPerPage: true }}
                            highlightOnHover={true}
                            noHeader={true}
                            striped={true}
                            theme="solarized"
                        />
                    </div>
                </div>
            </div>
        </div>
    </React.Fragment>;
}

