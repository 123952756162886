export const HOSTS_LIST_REQUEST = 'HOSTS_LIST_REQUEST';
export const HOSTS_LIST_SUCCESS = 'HOSTS_LIST_SUCCESS';
export const HOSTS_LIST_FAIL = 'HOSTS_LIST_FAIL';

export const HOSTS_SAVE_REQUEST = 'HOSTS_SAVE_REQUEST';
export const HOSTS_SAVE_SUCCESS = 'HOSTS_SAVE_SUCCESS';
export const HOSTS_SAVE_FAIL = 'HOSTS_SAVE_FAIL';

export const HOSTS_DELETE_REQUEST = 'HOSTS_DELETE_REQUEST';
export const HOSTS_DELETE_SUCCESS = 'HOSTS_DELETE_SUCCESS';
export const HOSTS_DELETE_FAIL = 'HOSTS_DELETE_FAIL';

export const SELECTED_HOST = 'SELECTED_HOST';

export const HOSTS_SIDEBAR_IS_TOGGLED = 'HOSTS_SIDEBAR_IS_TOGGLED';

export const HOST_AUTH_PERMISSIONS_REQUEST = 'HOST_AUTH_PERMISSIONS_REQUEST,';
export const HOST_AUTH_PERMISSIONS_SUCCESS = 'HOST_AUTH_PERMISSIONS_SUCCESS,';
export const HOST_AUTH_PERMISSIONS_FAIL = 'HOST_AUTH_PERMISSIONS_FAIL';

export const HOSTS_SAVE_EXTRAS_REQUEST = 'HOSTS_SAVE_EXTRAS_REQUEST';
export const HOSTS_SAVE_EXTRAS_SUCCESS = 'HOSTS_SAVE_EXTRAS_SUCCESS';
export const HOSTS_SAVE_EXTRAS_FAIL = 'HOSTS_SAVE_EXTRAS_FAIL';

export const HOSTS_SAVE_VERSEMENT_REQUEST = 'HOSTS_SAVE_VERSEMENT_REQUEST';
export const HOSTS_SAVE_VERSEMENT_SUCCESS = 'HOSTS_SAVE_VERSEMENT_SUCCESS';
export const HOSTS_SAVE_VERSEMENT_FAIL = 'HOSTS_SAVE_VERSEMENT_FAIL';

export const HOSTS_DELETE_VERSEMENT_REQUEST = 'HOSTS_DELETE_VERSEMENT_REQUEST';
export const HOSTS_DELETE_VERSEMENT_SUCCESS = 'HOSTS_DELETE_VERSEMENT_SUCCESS';
export const HOSTS_DELETE_VERSEMENT_FAIL = 'HOSTS_DELETE_VERSEMENT_FAIL';